import React, { useState } from 'react';
import menuButton from '../../assets/menuButton.svg';
import logo from '../../assets/mindly-logo.png';
import {Link} from 'react-router-dom';

import home from '../../assets/nav-home.svg' // the nav-home asset is not the same as on the figma and doesnt fit with the other nav icons
import notifications from '../../assets/nav-notifications.svg'
import newJournal from '../../assets/nav-new-journal.svg'
import myJournal from '../../assets/nav-my-journal.svg'
import insights from '../../assets/nav-bookmarks.svg'
import profile from '../../assets/nav-profile.svg'

const Sidenav = () => {
  const [isOpen, setIsOpen] = useState(false);

  const toggleSidenav = () => {
    setIsOpen(!isOpen);
  };
  const windowSize = window.innerWidth >=600;
  return (
    windowSize && (
    <div className="">
      <nav
        className={` text-white h-screen bg-mindly-light-green w-80 p-4 absolute left-0 top-0 bottom-0 transform flex flex-col justify-center ${
          isOpen ? 'translate-x-0' : '-translate-x-[1000px]'
        } transition-transform duration-300 z-20`}
      >
        <img className="w-2/3 absolute top-4 left-4" src={logo}></img>
        <button
          className="absolute top-4 right-4"
          onClick={toggleSidenav} // Close the nav when the "x" button is clicked
        >
            <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 14 14" fill="none">
            <path d="M12.9999 12.9999L7 7M7 7L1 1M7 7L13 1M7 7L1 13" stroke="#D5E2C4" strokeWidth="1.4" strokeLinecap="round" strokeLinejoin="round"/>
            </svg>
        </button>

        <div className=''>
            <ul className="text-center">
                {/* ADD LINKS */}
                <Link to="/hometest"><li className='bg-inherit hover:bg-[#87A073] text-[#CBC8C8] hover:text-white px-4 py-4 inline-flex rounded-lg w-5/6'><img className='mr-2' alt="home button" src={home}/>Home</li></Link>
                <Link to="/"><li className='bg-inherit hover:bg-[#87A073] text-[#CBC8C8] hover:text-white px-4 py-4 inline-flex rounded-lg w-5/6'><img className='mr-2' alt="notifications button" src={notifications}/>Notifications</li></Link>
                <Link to="/journal"><li className='bg-inherit hover:bg-[#87A073] text-[#CBC8C8] hover:text-white px-4 py-4 inline-flex rounded-lg w-5/6'><img className='mr-2' alt="new journal button" src={newJournal}/>New Journal</li></Link>
                <Link to="/hometest"><li className='bg-inherit hover:bg-[#87A073] text-[#CBC8C8] hover:text-white px-4 py-4 inline-flex rounded-lg w-5/6'><img className='mr-2' alt="my journal button" src={myJournal}/>My Journal</li></Link>
                {/*<Link to="/"><li className='bg-inherit hover:bg-[#87A073] text-[#CBC8C8] hover:text-white px-4 py-4 inline-flex rounded-lg w-5/6'><img className='mr-2' alt="insights button" src={insights}/>Insights</li></Link>*/}
                <Link to="/profile"><li className='bg-inherit hover:bg-[#87A073] text-[#CBC8C8] hover:text-white px-4 py-4 inline-flex rounded-lg w-5/6'><img className='mr-2' alt="profile button" src={profile}/>Profile</li></Link>
            </ul>
            <Link to="/qanda"><p className='absolute bottom-3 text-[#CBC8C8]'>ⓘ Help</p> </Link>
        </div>

      </nav>
      {/* Toggle Button */}
        <button className={(isOpen ? 'opacity-0' : 'absolute top-7 left-7 opacity-100')} onClick={toggleSidenav}>
        <img src={menuButton} />
        </button>
    </div>
  ))
};

export default Sidenav;
