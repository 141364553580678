import React, {useRef, useState} from 'react';
import profilePic from "../../assets/profile-pic.png";
import Cookies from "universal-cookie";

const Setup = () => {
  const cookies = new Cookies()

  const [imageSrc, setImageSrc] = useState(profilePic);
  const imageRef = useRef(null);
  const fileInputRef = useRef(null);
  const port = process.env.REACT_APP_BACKEND_PORT
  const uuid = cookies.get('uuid')

  // States for user info
  const [username, setUsername] = useState('')
  const [goals, setGoals] = useState('')
  const [name, setName] = useState('')
  const [bio, setBio] = useState('')
  const [pronouns, setPronouns] = useState('')

  const handleUsername = (e) => setUsername(e.target.value);
  const handleGoals = (e) => setGoals(e.target.value);
  const handleName = (e) => setName(e.target.value);
  const handleBio = (e) => setBio(e.target.value);
  const handlePronouns = (e) => setPronouns(e.target.value);

  const handleImageClick = () => {
    fileInputRef.current.click();
  };

  const handleFileChange = (event) => {
    const selectedFile = event.target.files[0];
    const objectURL = URL.createObjectURL(selectedFile);
    setImageSrc(objectURL);
  };

  const handleSubmit = async (e) => {
    e.preventDefault()

    const imageData = new FormData()
    imageData.append('profileImage', fileInputRef.current.files[0])
    for (const value of imageData.values()) {
      console.log(value);
    }

    // try {
    //   const response = await fetch(`http://localhost:${port}/api/users/${uuid}`, {
    //     method: 'PUT',
    //     mode: 'cors',
    //     credentials: 'include',
    //     headers: {
    //       'Content-Type': 'application/json',
    //     },
    //     body: {}
    //   })
    //   const message = await response.json()
    //   console.log(message)
    // } catch (error) {
    //   console.log(error)
    // }

    try {
      const fileResponse = await fetch(`http://localhost:${port}/api/file/profile`, {
        method: 'POST',
        mode: "cors",
        credentials: 'include',
        headers: {
          'Content-Type': 'multipart/form-data'
        },
        body: imageData
      })
      console.log(fileResponse)

    } catch (error) {
      console.log(error)
    }
    // try {
    //   const imgResponse = await fetch(`http://localhost:${port}/api/users/${uuid}/profilePic`, {
    //     method: 'PUT',
    //     mode: "cors",
    //     credentials: 'include',
    //     headers: {
    //       'Content-Type': 'application/json',
    //     },
    //     body: imageData
    //   })
    // } catch (error) {
    //   console.log(error)
    // }

  }

  return (
    <>
      <h1 className='my-8 text-3xl text-center font-semibold'>Setup Your Profile</h1>
      <form id={'setup-form'} className='flex justify-evenly' onSubmit={handleSubmit}>
        <div className='w-5/12 p-6'>
          <div className='mb-24'>
            <h2 className='text-mindly-green text-2xl'>Your Account Information</h2>
            <div className='mb-8 mt-4'>
              <label className='text-xl text-black font-bold'>Username</label>
              <br/>
              <input className='p-2 rounded-xl w-full h-12 border border-slate-900' type="text" value={username}
                     onChange={handleUsername}/>
            </div>
          </div>
          <div>
            <h2 className='text-mindly-green text-2xl'>Your Goals</h2>
            <div className='mb-8 mt-4'>
              <label className='text-xl text-black font-bold'>Current Goals Set</label>
              <br/>
              <textarea className='p-2 rounded-xl w-full h-36 border border-slate-900'
                        onChange={handleGoals}>{goals}</textarea>
            </div>

          </div>

        </div>
        <div className='w-5/12 p-6 '>
          <div>
            <h2 className={'text-3xl text-center font-semibold w-full'}>Profile Picture</h2>
            <img className='object-cover ml-auto mr-auto rounded-full w-72 h-72 cursor-pointer border border-solid border-mindly-green border-8 shadow' src={imageSrc}
                 ref={imageRef} onClick={handleImageClick}/>
            <input type="file" accept="image/*" className="hidden" onChange={handleFileChange} ref={fileInputRef}/>

            <div className='mb-8 mt-4'>
              <label className='text-xl text-black font-bold'>Name:</label>
              <br/>
              <input className='p-2 rounded-xl w-full h-12 border border-slate-900' type="text" value={name}
                     onChange={handleName}></input>
            </div>
            <div className='mb-8 mt-4'>
              <label className='text-xl text-black font-bold'>Bio:</label>
              <br/>
              <input className='p-2 rounded-xl w-full h-12 border border-slate-900' type="text" value={bio}
                     onChange={handleBio}/>
            </div>
            <div className='mb-8 mt-4'>
              <label className='text-xl text-black font-bold'>Pronouns:</label>
              <br/>
              <input className='p-2 rounded-xl w-full h-12 border border-slate-900' type="text" value={pronouns}
                     onChange={handlePronouns}/>
            </div>
          </div>
        </div>
      </form>
      <button type={'submit'} form={'setup-form'} className='border bg-mindly-green text-white p-4 px-6 rounded-xl block mx-auto my-4'>Save Input</button>
    </>
  );
};

export default Setup;