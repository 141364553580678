import React, {useEffect} from 'react'
import profilePic from '../../assets/profile-pic.png';
import {Link} from 'react-router-dom';
import {useState, useCallback} from 'react';
import Navbar from '../../components/Navbar/Navbar';
import MobileNavbar from '../../components/MobileNavbar/MobileNavbar';
import Sidenav from '../../components/Sidenav/Sidenav';
import GetUserInfo from "../../lib/GetUserInfo";
import UpdateUserInfo from "../../lib/UpdateUserInfo";
import Cookies from "universal-cookie";

const Profile = () => {
  //Navbar functions
  const [toggle, setToggle] = useState(true);
  const handleToggle = useCallback(() => setToggle(prevToggle => !prevToggle), [])

  // User Info Values
  const [username, setUsername] = useState('')
  const [name, setName] = useState('')
  const [bio, setBio] = useState('')
  const [goals, setGoals] = useState('')
  const [pronouns, setPronouns] = useState('')

  // Readonly toggles
  const [usernameReadOnly, setUsernameReadOnly] = useState(true)
  const [nameReadOnly, setNameReadOnly] = useState(true)
  const [bioReadOnly, setBioReadOnly] = useState(true)
  const [goalsReadOnly, setGoalsReadOnly] = useState(true)
  const [pronounsReadOnly, setPronounsReadOnly] = useState(true)

  // Edit toggles
  const editUsername = () => {

    if (!usernameReadOnly) {
      const data = {
        username: username,
      }
      UpdateUserInfo(data)
    }
    setUsernameReadOnly(!usernameReadOnly)
  }
  const editName = () => {
    if (!nameReadOnly) {
      const data = {
        name: name,
      }
      UpdateUserInfo(data)
    }

    setNameReadOnly(!nameReadOnly)
  }
  const editBio = () => {
    if (!bioReadOnly) {
      const data = {
        bio: bio,
      }
      UpdateUserInfo(data)
    }
    setBioReadOnly(!bioReadOnly)
  }
  const editGoals = () => {
    if (!goalsReadOnly) {
      const data = {
        goals: goals,
      }
      UpdateUserInfo(data)
    }
    setGoalsReadOnly(!goalsReadOnly)
  }
  const editPronouns = () => {
    if (!pronounsReadOnly) {
      const data = {
        pronouns: pronouns,
      }
      UpdateUserInfo(data)
    }
    setPronounsReadOnly(!pronounsReadOnly)
  }

  // onChange handlers
  const handleUsername = (e) => {
    console.log(username)
    setUsername(e.target.value)
  };
  const handleName = (e) => setName(e.target.value);
  const handleBio = (e) => setBio(e.target.value);
  const handleGoals = (e) => setGoals(e.target.value);
  const handlePronouns = (e) => setPronouns(e.target.value);

  const cookies = new Cookies()


  useEffect(() => {
    const setUserInfo = async () => {
      const userInfo = await GetUserInfo()
      setUsername(userInfo['username'])
      setName(userInfo['name'])
      setBio(userInfo['bio'])
      setGoals(userInfo['goals'])
      setPronouns(userInfo['pronouns'])
    }
    setUserInfo()
  }, [])



  return (
    <section className='font-mindly-main'>
      <Navbar onToggle={handleToggle} backgroundColorHover={true}/>
      <MobileNavbar toggle={toggle}/>
      <Sidenav/>
      <h1 className='my-8 text-3xl text-center font-semibold'>Your Profile</h1>
      <hr className='my-6'/>
      <div className='flex justify-evenly'>
        <div className='w-5/12 p-6'>
          <div className='mb-24'>
            <h2 className='text-mindly-green text-2xl'>Your Account Information</h2>
            <div className='mb-8 mt-4'>
              <label className='text-xl text-black font-bold'>Username</label>
              <br/>
              <input className={usernameReadOnly ? 'p-2 rounded-xl w-full h-12 border border-slate-900 bg-gray-200' : 'p-2 rounded-xl w-full h-12 border border-slate-900'}
                     type="username" value={username && username} readOnly={usernameReadOnly} onChange={handleUsername}/>
              <p className='w-full underline text-mindly-green text-right cursor-pointer' onClick={editUsername}>{usernameReadOnly ? 'Edit Username ↗' : 'Save Changes ↗'} </p>
            </div>
          </div>
          <div>
            <h2 className='text-mindly-green text-2xl'>Your Goals</h2>
            <div className='mb-8 mt-4'>
              <label className='text-xl text-black font-bold'>Current Goals Set</label>
              <br/>
              <textarea className={goalsReadOnly ? 'p-2 rounded-xl w-full h-36 border border-slate-900 bg-yellow-50' : 'p-2 rounded-xl w-full h-36 border border-slate-900'}
                        value={goals && goals} readOnly={goalsReadOnly} onChange={handleGoals}/>
              <p className='w-full underline text-mindly-green text-right cursor-pointer' onClick={editGoals}>Revise Goals ↗ </p>
            </div>

          </div>

        </div>
        <div className='w-5/12 p-6 '>
          <div>
            <img className='object-cover ml-auto mr-auto rounded-full w-72 h-72 cursor-pointer border border-solid border-mindly-green border-8 shadow' src={profilePic}/>
            <div className='mb-8 mt-4'>
              <label className='text-xl text-black font-bold'>Name:</label>
              <br/>
              <input className={nameReadOnly ? 'p-2 rounded-xl w-full h-12 border border-slate-900 bg-gray-200' : 'p-2 rounded-xl w-full h-12 border border-slate-900'}
                     type="text" value={name && name} readOnly={nameReadOnly} onChange={handleName}/>
              <p className='w-full underline text-mindly-green text-right cursor-pointer' onClick={editName}>{nameReadOnly ? 'Edit Name ↗' : 'Save Changes ↗'} </p>
            </div>
            <div className='mb-8 mt-4'>
              <label className='text-xl text-black font-bold'>Bio:</label>
              <br/>
              <input className={bioReadOnly ? 'p-2 rounded-xl w-full h-12 border border-slate-900 bg-gray-200' : 'p-2 rounded-xl w-full h-12 border border-slate-900'}
                     type="text" value={bio && bio} readOnly={bioReadOnly} onChange={handleBio}/>
              <p className='w-full underline text-mindly-green text-right cursor-pointer' onClick={editBio}>{bioReadOnly ? 'Edit Bio ↗' : 'Save Changes ↗'} </p>
            </div>
            <div className='mb-8 mt-4'>
              <label className='text-xl text-black font-bold'>Pronouns:</label>
              <br/>
              <input className={pronounsReadOnly ? 'p-2 rounded-xl w-full h-12 border border-slate-900 bg-gray-200' : 'p-2 rounded-xl w-full h-12 border border-slate-900'}
                     type="text" value={pronouns && pronouns} readOnly={pronounsReadOnly} onChange={handlePronouns}/>
              <p className='w-full underline text-mindly-green text-right cursor-pointer' onClick={editPronouns}>{pronounsReadOnly ? 'Edit Pronouns ↗' : 'Save Changes ↗'} </p>
            </div>

          </div>

        </div>
      </div>
      <Link to="/">
        <button className='border bg-mindly-green text-white p-4 px-6 rounded-xl block mx-auto my-4'>Go Home</button>
      </Link>

    </section>
  )
}

export default Profile;
