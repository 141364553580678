  export function formatEntryDate(numberedDate) {
    const months = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];
    const dayOfWeek  = ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"];
    const nodash = numberedDate.split("-");
    const numericArray = nodash.map(element => parseInt(element));
  
    const date = new Date(numericArray[0], numericArray[1]-1, numericArray[2]);
    const dayOfMonth = date.getDate()
    const suffix = (dayOfMonth >= 11 && dayOfMonth <= 13) ? 'th' : (dayOfMonth % 10 === 1) ? 'st' : (dayOfMonth % 10 === 2) ? 'nd' : (dayOfMonth % 10 === 3) ? 'rd' : 'th';
    return `${dayOfWeek[date.getDay()]}, ${months[date.getMonth()]} ${dayOfMonth}${suffix}`
}

export function setAllJournalDataDates(monthSetter, yearSetter, journalDataSetter){
  const today = new Date();
  const allDays = [];
  const currentYear = today.getFullYear();
  const currentMonth = today.getMonth();
  const lastDayOfMonth = new Date(currentYear, currentMonth + 1, 0).getDate();
  for (let day=1; day<=lastDayOfMonth; day++){
      const temp = new Date(currentYear, currentMonth, day);
      const currentDateInfo = {
        name: formatEntryDate(temp.toISOString().substring(0,10)),
        journalEntriesData: []
      }
    allDays.push(currentDateInfo)
  }   
  const months = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];
  monthSetter(months[currentMonth]);
  yearSetter(currentYear);
  journalDataSetter(allDays);
  return allDays;
}