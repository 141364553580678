import React from "react";

const SignUpButton = (props) => {

    const text = props.text
    const image = props.image
    const bgColor = props.bg
    const clickFunc = props.onClick // Should be used later to implement any onClick functions



    return (
        <button className={`flex ${bgColor} w-80  lg:w-96 h-14 flex-shrink-0 flex items-center justify-center rounded-2xl gap-2 shadow-lg`} onClick={clickFunc}>
            {image && <img src={image} className={'h-5 w-5'}/>}
            <span className={`${bgColor === 'bg-white' ? 'text-black' : 'text-white'} font-roboto`}>{text}</span>
        </button>
    )
}

export default SignUpButton