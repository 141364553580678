import React from 'react'
import home from '../../assets/nav-home.svg';
import search from '../../assets/nav-search.svg';
import notifications from '../../assets/nav-notifications.svg';
import newJournal from '../../assets/nav-new-journal.svg';
import myJournal from '../../assets/nav-my-journal.svg';
import bookmarks from '../../assets/nav-bookmarks.svg';
import profile from '../../assets/nav-profile.svg';
import logo from '../../assets/mindly-logo-trees.svg';
import {Link} from 'react-router-dom';

const mobileNavbar = ({toggle}) => {
const windowSize = (window.innerWidth <= 600);

  return (
    windowSize && toggle && <section className="fixed w-screen z-[2000] top-0 font-mindly-main">
        <nav className="w-3/5 absolute bg-mindly-light-green backdrop-blur-lg min-h-screen h-fit">
            <ul className="p-4">
                    
                    <div className="flex-col h-fit ml-3 mt-20 mr-6">
                        <Link to='/'><li className="flex w-fit my-6 mx-2 text-xl font-semibold text-mindly-gray hover:font-normal hover:text-black hover:text-2xl">
                            <img className="mr-4" alt="home" src={home} />
                            Home
                        </li></Link>
                        <Link to=''><li className="flex w-fit my-6 mx-2 text-xl font-semibold text-mindly-gray hover:font-normal hover:text-black hover:text-2xl">
                            <img className="mr-4" alt="search" src={search} />
                            Search
                        </li></Link>
                        <Link to=''><li className="flex w-fit my-6 mx-2 text-xl font-semibold text-mindly-gray hover:font-normal hover:text-black hover:text-2xl">
                            <img className="mr-4" alt="notifications" src={notifications} />
                            Notifications
                        </li></Link>
                        <Link to='/NewEntry'><li className="flex w-fit my-6 mx-2 text-xl font-semibold text-mindly-gray hover:font-normal hover:text-black hover:text-[22px]">
                            <img className="mr-4" alt="new journals" src={newJournal} />
                            New Journal
                        </li></Link>
                        <Link to='/MyJournalMobile'><li className="flex w-fit my-6 mx-2 text-xl font-semibold text-mindly-gray hover:font-normal hover:text-black hover:text-2xl">
                            <img className="mr-4" alt="my journal" src={myJournal} />
                            My Journal
                        </li></Link>
                        <Link to='BookmarksMobile'><li className="flex w-fit my-6 mx-2 text-xl font-semibold text-mindly-gray hover:font-normal hover:text-black hover:text-2xl">
                            <img className="mr-4" alt="bookmarks" src={bookmarks} />
                            Bookmarks
                        </li></Link>
                        <Link><li className="flex w-fit my-6 mx-2 text-xl font-semibold text-mindly-gray hover:font-normal hover:text-black hover:text-2xl">
                            <img className="mr-4" alt="profile" src={profile} />
                            Profile
                        </li></Link>
                    </div>
                    <li className="mobile-menu-logo">
                        <img src={logo} className="w-8/12 mt-16 mb-8 max-w-[150px] mx-auto" alt="mindly logo" />
                    </li>
                    <li className="text-mindly-green text-center text-sm">
                        <p>Created by Project: Empower</p>
                    </li>
            </ul>
        </nav>
    </section>
  )
}

export default mobileNavbar;
