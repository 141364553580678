import React from 'react';
import ThumbnailButton from '../../assets/bookmark-thumbnail.png';
import Heart from '../../assets/favorite.png';
import Share from '../../assets/share.png';
import Save from '../../assets/filled-save.png';
import Nav from '../../assets/nav.png';
import Profile from '../../assets/profile.png';
import {Link} from 'react-router-dom';
import Navbar from "../../components/Navbar/Navbar";


const BookmarksMobile = () => {
    return (
        <>
            <Navbar backgroundColorHover={true}/>
            <div className={'mx-6 my-4 flex flex-col gap-6 align-middle justify-center'}>
                <div className={'flex flex-row justify-between mb-2'}>
                    <img src={Nav}/>
                    <img src={Profile}/>
                </div>
                <h1 className={'text-6xl font-mindly-main font-normal'}>Your <br/> Bookmarks.</h1>
                <div className={'bg-bookmark-gray rounded p-3'}>
                    <p className={'text-black text-base leading-4 font-normal font-mindly-main mb-1.5'}>Title:</p>
                    <p className={'text-black text-base leading-4 font-normal font-mindly-main mb-1.5'}>Date:</p>
                    <p className={'text-black text-base leading-4 font-normal font-mindly-main mb-1.5'}>Emotions:</p>
                    <div className={'flex flex-col bg-white'}>
                        <p className={'font-mindly-main text-black text-center p-10 pb-6'}>Thumbnail</p>
                        <img className={'self-end mb-3'} src={ThumbnailButton}/>
                    </div>
                    <div className={'flex flex-row justify-center gap-28 mt-3 '}>
                        <img className={'w-8 h-6'} src={Heart}/>
                        <img className={'w-8 h-6'} src={Share}/>
                        <img className={'w-8 h-6'} src={Save}/>
                    </div>
                </div>
                <div className={'bg-bookmark-gray rounded p-3'}>
                    <p className={'text-black text-base leading-4 font-normal font-mindly-main mb-1.5'}>Title:</p>
                    <p className={'text-black text-base leading-4 font-normal font-mindly-main mb-1.5'}>Date:</p>
                    <p className={'text-black text-base leading-4 font-normal font-mindly-main mb-1.5'}>Emotions:</p>
                    <div className={'flex flex-col bg-white'}>
                        <p className={'font-mindly-main text-black text-center p-10 pb-6'}>Thumbnail</p>
                        <img className={'self-end mb-3'} src={ThumbnailButton}/>
                    </div>
                    <div className={'flex flex-row justify-center gap-28 mt-3 '}>
                        <img className={'w-8 h-6'} src={Heart}/>
                        <img className={'w-8 h-6'} src={Share}/>
                        <img className={'w-8 h-6'} src={Save}/>
                    </div>
                </div>
                <button className={'bg-black rounded-md max-w-fit text-white font-mindly-main self-center px-8 py-2 mt-10'}>Create New</button>
            </div>
        </>
    );

};

export default BookmarksMobile;