import {Routes, Route, useNavigate, Link} from 'react-router-dom'
import SignUpButton from "../../components/SignUpButton/SignUpButton";
import apple from '../../assets/apple.svg';
import email from '../../assets/email.svg'
import gmail from '../../assets/google.svg'
import google from "../../assets/google.svg";
import React from "react";
import {useEffect, useState} from "react";
import Cookies from "universal-cookie";
import { GoogleLogin, GoogleOAuthProvider } from '@react-oauth/google';
import GoogleAuth from '../Login/LoginInput/GoogleAuth';

const SignIn = () => {
  const navigate = useNavigate();
  const cookies = new Cookies()

  // Used to check if mobile or not
  const [width, setWidth] = useState(window.innerWidth);

  function handleWindowSizeChange() {
    setWidth(window.innerWidth);
  }
  useEffect(() => {
    window.addEventListener('resize', handleWindowSizeChange);
    return () => {
      window.removeEventListener('resize', handleWindowSizeChange);
    }
  }, []);

  const isMobile = width <= 768;

  const navigateToEmail = () => {
    navigate('/register/email');
  };

  const navigateToLogin = () => navigate('/login');

  return (
    <header className="flex flex-col items-center font-['EB+Garamond'] sm:justify-center min-h-screen ">
      <h1 className="font-bold text-[48px] text-center mt-20 sm:mt-0">Sign Up</h1>
      <p className="text-[20px] mb-7 mr-5 ml-5 py-4 text-black text-center">Create your Mindly account and start capturing your journey!</p>
      <div className="fmt-20 flex flex-col gap-6">
        <SignUpButton text={'Sign up with Apple'} bg={'bg-apple-black'} image={apple}/>
        <GoogleAuth/>
        <SignUpButton text={'Continue with email'} bg={'bg-white'} image={email} onClick={() => navigateToEmail()}/>
      </div>
      <p className={'text-mindly-green font-roboto mt-8 mx-7 text-center text-base'}>
        By continuing, you agree with our <u>Terms of Use</u> and <u>Privacy Policy</u>.
      </p>

      <div className={'flex items-center mt-12 sm:mt-8'}>
        <Link to={'/login'} className={'text-input-gray font-roboto text-base'}>
          <u>Do you already have an account?</u>
        </Link>
        {!isMobile && <button style={{
            backgroundColor: '#CBC8C8',
            width: '120px',
            height: '50px',
            borderRadius: '15px',
            textAlign: 'center',
            color: 'white',
            marginLeft: '20px'
          }}

                  onClick={navigateToLogin}
          >
            Login
          </button>}
      </div>
      <p className="fixed bottom-5 right-5 opacity-50 font-['Roboto']">Made by Project:Empower</p>
      <Link to={'https://projectempower.io/'} className="fixed bottom-10 sm:bottom-5 sm:right-7 sm:opacity-50 font-mindly-main sm:font-['Roboto'] sm:text-input-gray text-xl sm:text-base font-normal">
        Created by Project:Empower
      </Link>
    </header>
  );
};

export default SignIn;
  
