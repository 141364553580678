const GetUserInfo = async () => {

  /**
   * This function returns all the user info
   * @returns {boolean} status
   * @returns {Object}
   * @property {string[]} badges
   * @property {string[]} blockedUsers
   * @property {string} email
   * @property {string[]} name
   * @property {string} password
   * @property {string} registrationDate
   * @property {string} registrationMethod
   * @property {Object} settings
   * @property {Object} streak
   * @property {string} uuid
   */

  const port = process.env.REACT_APP_BACKEND_PORT

  const response = await fetch(`http://localhost:${port}/api/users`, {
    method: 'GET',
    mode: 'cors',
    credentials: 'include',
    headers: {
      'Content-Type': 'application/json',
    },
  })

  if (response.status === 401) {
    return 401
  }
  const userInfo = await response.json()
  return userInfo['0']

}

export default GetUserInfo
