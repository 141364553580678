import LoginInput from "./LoginInput";
import ErrorImage from "../../../assets/error.png";
import React, {useState} from "react";
import {Link} from "react-router-dom";
import {useNavigate} from "react-router";
import resource from "../../ResourceLibrary/Resource";
import Cookies from "universal-cookie";


const EmailLogin = () => {

  const navigate = useNavigate()
  const cookies = new Cookies()

  const returnToLogin = (e) => {
    e.preventDefault()
    navigate('/login')
  }

  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const [error, setError] = useState(false)

  const handleSubmit = async (e) => {
    e.preventDefault()

    const createExpiryDate = () => {
      let expiryDate = new Date();
      const month = (expiryDate.getMonth() + 1) % 12;
      expiryDate.setMonth(month);
      return expiryDate
    }

    const loginDetails = {
      email: email,
      password: password
    }

    const port = process.env.REACT_APP_BACKEND_PORT
    try {
      const response = await fetch(`http://localhost:${port}/api/auth/signin`, {
        method: 'POST',
        mode: "cors",
        credentials: 'include',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(loginDetails)

      });

      if (response.status === 401) {
        setError(true)
      } else{
        const data = await response.json()
        console.log(data)
        navigate('/home')
      }


    } catch(error) {
      console.log(error)
    }

  }

  const handleEmail = (e) => {
    setError(false)
    setEmail(e.target.value)
  }
  const handlePassword = (e) => {
    setError(false)
    setPassword(e.target.value)
  }

  return (
    <>
      <form className={'mt-16 flex flex-col gap-6 align-middle justify-center'} onSubmit={handleSubmit}>
        <LoginInput label={'Email'} placeholder={'Enter your email.'} type={'text'} value={email} onChange={handleEmail} error={error}/>
        <LoginInput label={'Password'} placeholder={'Enter your password.'} type={'password'} value={password} onChange={handlePassword} error={error}/>
        <Link className={'text-right mr-5 underline text-mindly-green'}>I forgot my password</Link>
        <div className={'flex gap-2 justify-center mt-4'}>
          <input type={'checkbox'} id={'keepLogin'} className={'border-input-gray checked:text-input-gray'}/>
          <label htmlFor={'keepLogin'} className={'font-roboto'}>Stay logged in</label>
        </div>
        <div className={'flex justify-center gap-7 mt-2'}>
          <button onClick={returnToLogin}
                  type={'button'}
                  className={'h-12 w-32 border border-input-gray text-input-gray rounded-2xl font-roboto'}>Go Back
          </button>
          <button
            className={'h-12 w-32 bg-login-light-green text-white rounded-2xl font-roboto'}>Log in</button>
        </div>
        <div className={'flex justify-center gap-5 items-center mt-10'}>
          <Link to={'/register'} className={'underline text-input-gray roboto'}>Don't have an account yet?</Link>
          <Link to={'/register'}>
            <button className={'h-12 w-32 bg-input-gray text-white rounded-2xl font-roboto'}>Sign up</button>
          </Link>
        </div>

      </form>
    </>
  )
}




// NOT USED DO NOT EDIT UNDER

const ErrorEmailLogin = () => {
  const navigate = useNavigate()

  const returnToLogin = () => {
    navigate('/login')
  }

  return (
    <form className={'mt-16 flex flex-col gap-6 align-middle justify-center'}>
      <div className={'mb-7'}>
        <LoginInput error={'true'} label={'Email'} placeholder={'example@gmail.com'} type={'text'}/>
        <img className={'inline pl-5 w-10 h-5'} src={ErrorImage}/>
        <p className={'pb-5 pl-2 text-mindly-pink text-sm font-roboto inline'}>Email not found</p>
      </div>
      <div className={'mb-2'}>
        <LoginInput error={'true'} label={'Password'} placeholder={'Enter your password.'} type={'password'}/>
        <img className={'inline pl-5 w-10 h-5'} src={ErrorImage}/>
        <p className={'pb-5 pl-2 text-mindly-pink text-sm text-inline font-roboto inline'}>Password incorrect</p>
        <Link className={'pl-16 underline text-mindly-pink inline font-roboto text-sm'}>I forgot my password</Link>
      </div>
      <div className={'flex gap-2 justify-center mt-4'}>
        <input type={'checkbox'} id={'keepLogin'} className={'border-input-gray checked:text-input-gray'}/>
        <label htmlFor={'keepLogin'} className={'font-roboto'}>Stay logged in</label>
      </div>
      <div className={'flex justify-center gap-7 mt-2'}>
        <button onClick={returnToLogin}
                type={'button'}
                className={'h-12 w-32 border border-input-gray text-input-gray rounded-2xl font-roboto'}>Go Back
        </button>
        <button type={'submit'} className={'h-12 w-32 bg-login-light-green text-white rounded-2xl font-roboto'}>Log in
        </button>
      </div>
      <div className={'flex justify-center gap-5 items-center mt-10'}>
        <Link className={'underline text-input-gray roboto'}>Don't have an account yet?</Link>
        <button className={'h-12 w-32 bg-input-gray text-white rounded-2xl font-roboto'}>Sign up</button>
      </div>

    </form>
  )
}

export default EmailLogin 