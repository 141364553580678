const CheckLogin = async () => {

  /**
   * This function checks if the user is logged in
   * @returns {boolean} loginTrue
   */

  const port = process.env.REACT_APP_BACKEND_PORT

  const response = await fetch(`http://localhost:${port}/api/auth/signin/status`, {
    method: 'GET',
    mode: 'cors',
    credentials: 'include',
    headers: {
          'Content-Type': 'application/json',
    },
  })

  const loginTrue = response.status === 200

  return loginTrue;
}

export default CheckLogin