import React, {useEffect, useState} from "react";
import SignUpButton from "../../components/SignUpButton/SignUpButton";
import LoginInput from "./LoginInput/LoginInput";
import {useNavigate, Outlet} from "react-router";
import apple from "../../assets/apple.svg";
import google from "../../assets/google.svg";
import email from "../../assets/email.svg"
import logo from "../../assets/mindly-logo.png"
import {Link, Route, Routes, useLocation} from "react-router-dom";
import EmailLogin from "./LoginInput/EmailLogin";
import UserPassLogin from "./LoginInput/UserPassLogin";
import Cookies from "universal-cookie";
import { GoogleLogin, GoogleOAuthProvider } from '@react-oauth/google';
import GoogleAuth from "./LoginInput/GoogleAuth";
const LoginBody = () => {

  const location = useLocation()
  const navigate = useNavigate()

  const setEmail = () => {
    navigate('/login/email')
  }

  const setUsername = () => {
    navigate('/login/username')
  }

  const LoginButtons = () => {
    return (
        <div className={'mt-20 flex flex-col gap-6'}>
            <SignUpButton text={'Sign up with Apple'} bg={'bg-apple-black'} image={apple}/>
            <GoogleAuth/>
            <SignUpButton text={'Continue with email'} bg={'bg-white'} image={email} onClick={() => setEmail()}/>
        </div>
    )
}

  return (
    <>
      {location.pathname === '/login' && <LoginButtons/>}
      <Routes>
        <Route path={'email'} element={<EmailLogin/>}/>
      </Routes>
      <Outlet/>
      <Link to={'https://projectempower.io/'}
            className={'absolute bottom-8 lg:bottom-6 right-8 roboto text-input-gray'}>Made by Project:Empower</Link>
    </>
  )

}


const Desktop = () => {
  return (
    <div className={'flex flex-col justify-center items-center h-screen'}>
      <h1 className={'text-black text-center font-mindly-main text-5xl font-medium leading-normal'}>Welcome Back</h1>
      <p1 className={'text-black text-center font-mindly-main text-lg font-normal leading-normal'}>
        Welcome back to Mindly. Reconnect with your thoughts and continue your journey.
      </p1>
      <LoginBody/>

    </div>
  )
}

const Mobile = () => {
  return (
    <div className={'h-screen flex flex-col items-center'}>
      <img src={logo} alt={'mindly logo'} className={'w-8/12 ml-auto mr-auto mt-7'}/>
      <h1 className={'text-black text-center font-mindly-main text-4xl font-medium leading-normal mt-5'}>Login</h1>
      <LoginBody/>

    </div>
  )
}


const Login = () => {

  const cookies = new Cookies()
  const navigate = useNavigate()

  const googleClientID = process.env.REACT_APP_GOOGLE_CLIENT_ID

  // Used to check if mobile or not
  const [width, setWidth] = useState(window.innerWidth);

  function handleWindowSizeChange() {
    setWidth(window.innerWidth);
  }

  useEffect(() => {
    window.addEventListener('resize', handleWindowSizeChange);
    return () => {
      window.removeEventListener('resize', handleWindowSizeChange);
    }
  }, []);

  const navigateHome = () => {
    navigate('/home')
  }

  useEffect(() => {
    const script = document.createElement('script')
    script.src = "https://apis.google.com/js/platform.js"
    script.async = true;

    document.body.appendChild(script)

    return () => {
      document.body.removeChild(script);
    }
  }, []);

  const isMobile = width <= 768;

  return (
    <>

      {isMobile ? <Mobile/> : <Desktop/>}

    </>
  )
}

export default Login