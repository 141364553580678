import React from 'react'
import menuButton from '../../assets/menuButton.svg'
import logo from '../../assets/logo.png'
import profile from '../../assets/profile.svg'
import './Navbar.css'
import {Link} from 'react-router-dom';
let cssClass;

let visible;
const Navbar = ({onToggle, position, backgroundColorHover}) => {
  let windowSize = window.innerWidth <=600;
  if(position){
    cssClass = 'absolute'
  }
  else{
    cssClass = 'fixed'
  }
  if(windowSize) {
    visible = 'flex'
    
  }
  else{
    visible = 'hidden'
    
  }
  
  return (
    <nav>
      <ul className="py-3 px-4 flex justify-between">
        <Link to="#">
          <li>
          {backgroundColorHover ?
          <div className='circlehov'>
            <img onClick={onToggle} className={`z-[3000] ${visible} ${cssClass}`} alt="menu button" src={menuButton}/>
          </div>:
             <img onClick={onToggle} className={`z-[3000] ${cssClass}`} alt="menu button" src={menuButton}/>
          }
          </li>
          </Link>
        <Link to="/"><li><img alt="Mindly Logo" src={logo}/></li></Link>
        <Link to="/profile">
          <li>
            {backgroundColorHover ?
            <div className='circlehov'>
              <img alt="profile button" src={profile}/>
            </div> :
              <img alt="profile button" src={profile}/>
            }
          </li>
          </Link>
      </ul>
    </nav>
  )
}

export default Navbar;
