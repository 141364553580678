import React from 'react'
import Resource from './Resource'

const ResourceBox = ({ resourceTitle, data }) => {
  const isBook = resourceTitle==="Books";
  return (
    <div className='resourceBox'>
        <h1>{resourceTitle}</h1>
        {data.map((article)=>{
            return <Resource title={article.title} description={article.description} isBook={isBook} />
        })}
    </div>
  )
}

export default ResourceBox