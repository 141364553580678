import React from 'react'
import Calendar from 'react-calendar'; 
import 'react-calendar/dist/Calendar.css';
import './CalendarPopup.css';
import {useState} from 'react';
import {Link} from 'react-router-dom';
const CalendarPopup = ({show, setShow}) => {

    
    const d = new Date();
    let tempMonth = d.getMonth();
    let tempYear = d.getFullYear();
    let [month, setMonth] = useState(tempMonth);
    let [year, setYear] = useState(tempYear);
    const months = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];
    const [date, setDate] = useState();

    const prevMonth = () =>{
        if(month == 0){
            setMonth(11);
            setYear(year = year - 1);
        }
        else{
            setMonth(month = month - 1);
        }
        
    }
    const nextMonth = () =>{
        
        if(month == 11){
            setMonth(0);
            setYear(year = year + 1);
        }
        else{
            setMonth(month = month + 1);
        }
        
    }
    const displayNextMonth = () =>{
        if(month == 11){

            return (0);
            
        }

        else{
            return(month + 1);    
       }
    }
    const displayPrevMonth = () =>{
        if(month == 0){

            return (11);
        }
        else{
            return(month - 1);    
       }
    }
   
    
  return (

    show && (
    <section className='right-48 absolute font-mindly-main'>
        <div className='w-[400px] shadow-[0px_4px_10px_1px_rgba(0,0,0,0.10)] background: rgba(255, 255, 255, 0.9) backdrop-blur-[50px] rounded-[15px] text-center py-8'>
           
           <div onClick={prevMonth} className='hover:bg-mindly-gray hover:text-white my-2 mx-auto w-fit px-24 py-3 rounded-2xl text-2xl  text-mindly-gray font-semibold'> {months[displayPrevMonth()]} {year}</div>
            <div className='my-2 mx-auto w-fit px-24 py-3 rounded-2xl text-2xl bg-mindly-green text-white font-semibold'>{months[month]} {year}</div>
            <div onClick={nextMonth} className='hover:bg-mindly-gray hover:text-white my-2 mx-auto w-fit px-24 py-3 rounded-2xl text-2xl  text-mindly-gray font-semibold'>{months[displayNextMonth()]} {year}</div>
           
        </div>
        <div className='py-6 my-12 w-[400px] shadow-[0px_4px_10px_1px_rgba(0,0,0,0.10)] background: rgba(255, 255, 255, 0.9) backdrop-blur-[50px] rounded-[15px]'>
            <h1 className='my-8 text-mindly-green text-right mr-4'>{months[month]} {year}</h1>
            <div className='flex my-4 justify-center items-center'>

            <Calendar calendarType="US" showNavigation={null} onChange={setDate} view="month" activeStartDate={new Date(year, month)} />
                
            </div>
            <hr className='h-px mx-auto my-8 bg-mindly-gray border-0 w-11/12 ' />
            <div>

                <div></div>

            </div>
            <br />

            <div className='flex justify-center'>
                    
                    <Link to="/journal">
                    <button className='text-mindly-gray w-fit px-12  my-2 mr-0 border border-dashed border-mindly-gray  h-36 rounded-2xl'>
                        <p className='text-mindly-gray text-center'>No journals yet.</p>
                        <p className='text-mindly-gray text-center'>Start capturing your thoughts!</p>
                    </button>
                    </Link>
                </div>
        </div>
    </section>)
  )
}

export default CalendarPopup