import React from 'react'

const JournalEntry = ({ journalTitle, emotion}) => {
  return (
    <div className='journalEntry'>
      <h1>{journalTitle}</h1>
      <h2>{emotion}</h2>
    </div>
  )
}

export default JournalEntry