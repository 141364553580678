import { VscCheck, VscChromeClose } from 'react-icons/vsc';

const Circle = ({ check }) => {
  let color, text;

  if (check === true) {
    color = '#87A073';
    text = <VscCheck />
  } else if (check === false) {
    color = '#F4A5AE';
    text = <VscChromeClose />
  } else {
    color = '#CBC8C8'; // Default gray color
    text = <VscChromeClose/>
  }

  return (
    <span className="w-5 h-5 rounded-full mb-2 text-white flex items-center justify-center" style={{ backgroundColor: color }}>
      {text}
    </span>
  );
};

export default Circle;
