import React from "react";
import { useNavigate } from "react-router";
import Cookies from "universal-cookie";
import { useGoogleLogin } from "@react-oauth/google";
import google from "../../../assets/google.svg";
import SignUpButton from "../../../components/SignUpButton/SignUpButton";


const GoogleAuth = () => {
  const port = process.env.REACT_APP_BACKEND_PORT
  const backendURL = process.env.REACT_APP_BACKEND_URL

  const googleLogin = useGoogleLogin({
    flow: 'auth-code',
    onSuccess: async (codeResponse) => {
      console.log(codeResponse);
      const response = await fetch(backendURL + "/auth/google", {
        method: 'GET',
        mode: 'cors',
        credentials: 'include',
        headers: {
          'Content-Type': 'application/json'
        }
      });
      const tokens = await response.json();
      console.log(tokens);
    },
    onError: errorResponse => console.log(errorResponse),
  });


  return (
    <SignUpButton
      text="Sign up with Google"
      image={google}
      bg="bg-white"
      onClick={() => googleLogin()}
    />
  );

};

export default GoogleAuth;
