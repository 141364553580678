import React from "react";
import Navbar from "../../../components/Navbar/Navbar";


const LoginInput = (props) => {

  const label = props.label
  const placeholder = props.placeholder
  const type = props.type
  const value = props.value
  const submitFunc = props.onSubmit // Should be used on any function for submitting
  const onChange = props.onChange
  const error = props.error

  return (
    <>
      <div className={'flex flex-col gap-3'}>
        <label className={'ml-6 font-roboto'}>{label}</label>
        {error ?
          <input required={true}
                 className={'border w-96 h-12 rounded-3xl border-mindly-pink placeholder-mindly-pink indent-6 font-roboto mb-3'}
                 type={type} placeholder={placeholder} onChange={onChange} value={value}/> :
          <input required={true}
                 className={'border w-96 h-12 rounded-3xl border-input-gray indent-6 font-roboto mb-3'}
                 type={type} placeholder={placeholder} onChange={onChange} value={value}/>}

      </div>
    </>
  )
}

export default LoginInput