const GetJournals = async () => {

  /**
   * This function gets all journal entries
   * @returns {Object[]} journals
   */

  const port = process.env.REACT_APP_BACKEND_PORT

  const response = await fetch(`http://localhost:${port}/api/journal/all`, {
    method: 'GET',
    mode: 'cors',
    credentials: 'include',
    headers: {
      'Content-Type': 'application/json',
    },
  })

  const journals = await response.json()
  console.log(journals)
  return journals

}

export default GetJournals
