import React, { useState } from 'react';
import Select from 'react-select';
import './FontPicker.css';
const fontOptions = [
    { value: 'PT Serif, serif', label: 'PT Serif' },
    { value: 'Comfortaa, cursive', label: 'Comfortaa' },
    { value: 'Montserrat, sans-serif', label: 'Montserrat' },
    { value: 'Fira Code, monospace', label: 'Fira Code' },
    { value: 'Cedarville Cursive, cursive', label: 'Cedarville Cursive' },
    { value: 'Dosis, sans-serif', label: 'Dosis' },
  // Add more fonts as needed
];

function FontPicker({selectedFont, setSelectedFont}) {

  const handleFontChange = (selectedOption) => {
    setSelectedFont(selectedOption);
  };

  return (
    <div className='mx-4 z-[0px]'>
      
      <Select 
        className='select-box'
        options={fontOptions}
        value={selectedFont}
        onChange={handleFontChange}
        placeholder="Select a font"
      />
      {console.log(selectedFont)}
    </div>
  );
}

export default FontPicker;