import Circle from './components/Circle';
import {useState} from 'react';
import Text from './components/Text'
import {Link} from "react-router-dom";
import {useNavigate} from "react-router";
import {useEffect} from "react";
import logo from '../../assets/mindly-logo.png'
import {
  specialCharacters,
  emailCheck,
  passwordLength,
  charLimit,
  numberCheck,
  lowercaseCheck,
  uppercaseCheck,
  createAccount
} from "./AccountHandler";
import Cookies from "universal-cookie";
import React from "react";


const Desktop = () => {

  const navigate = useNavigate()

  const [password, setPassword] = useState('');
  const [username, setUsername] = useState('');
  const [firstName, setFirstName] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [passwordDisplay, setPasswordDisplay] = useState('password');
  const [Email, setEmail] = useState('');

  const handlePassword = (event) => setPassword(event.target.value);;
  const handlePasswordConfirm = (event) => setConfirmPassword(event.target.value);
  const handleEmail = (event) => setEmail(event.target.value);
  const handleUsername = (e) => setUsername(e.target.value);
  const handleFirstName = (e) => setFirstName(e.target.value);
  const returnToRegister = () => navigate('/register');
  const cookies = new Cookies()

  const createUser = async (e) => {

    const createExpiryDate = () => {
      let expiryDate = new Date();
      const month = (expiryDate.getMonth() + 1) % 12;
      expiryDate.setMonth(month);
      return expiryDate
    }

    e.preventDefault()
    const response = await createAccount(username, firstName, Email, password, confirmPassword)

    if (response === "invalid") {
      alert('There is an error with your info')

    } else {
      navigate('/home')
    }

  }

  return (
    <div className="flex items-center justify-center min-h-screen w-screen">
      <div className="flex flex-col items-center font-['EB+Garamond'] w-full">
        <h1 className="font-mindly-main text-[48px] text-center py-4">Sign up</h1>
        <p className="text-[20px] mb-5 mr-5 ml-5 text-black font-mindly-main">Create your Mindly account and start
          capturing your journey!</p>
        <form className={'flex flex-col items-center'} onSubmit={createUser}>
          <div className={'flex gap-24'}>
            <div>
              <div className={'mb-4'}>
                <div className={'font-roboto ml-4 mb-2'}>Username</div>
                <input className={'border border-input-gray py-4 px-4 w-96 rounded-[6.25rem] font-roboto'}
                       type={'text'}
                       placeholder={'Enter a username.'}
                       value={username}
                       onChange={handleUsername}
                />
              </div>
              <div className={'ml-4'}>
                <div className={'flex items-center gap-2'}>
                  <Circle check={specialCharacters(username)}/>
                  <Text check={specialCharacters(username)} text={'No special characters'}/>
                </div>
                <div className={'flex items-center gap-2'}>
                  <Circle check={charLimit(username)}/>
                  <Text check={charLimit(username)} text={'Max 32 characters'}/>
                </div>
              </div>
            </div>
            <div>
              <div className={'mb-4'}>
                <div className={'font-roboto ml-4 mb-2'}>
                  First name
                </div>
                <input className={'border border-input-gray py-4 px-4 w-96 rounded-[6.25rem] font-roboto'}
                       type={'text'}
                       placeholder={'Enter a first name.'}
                       value={firstName}
                       onChange={handleFirstName}
                />
              </div>
              <div className={'ml-4'}>
                <div className={'flex items-center gap-2'}>
                  <Circle check={specialCharacters(firstName)}/>
                  <Text check={specialCharacters(firstName)} text={'No special characters'}/>
                </div>
                <div className={'flex items-center gap-2'}>
                  <Circle check={charLimit(firstName)}/>
                  <Text check={charLimit(firstName)} text={'Max 32 characters'}/>
                </div>
              </div>
            </div>
          </div>
          <div>
            <div className={'flex mt-5 mb-2 ml-5 text-base font-normal font-roboto'}>
              Email
            </div>
            <div className="flex flex-col items-center font-['Roboto'] font-medium">
              <input
                placeholder="Enter your email. Ex&#41; yourmindlyemail@example.com"
                className="bg-white text-#CBC8C8 border-input-gray py-4 px-4 w-[54rem] flex space-x-2 justify-center font-roboto font-normal border rounded-[6.25rem]"
                value={Email}
                onChange={handleEmail}
              />
              <div/>
            </div>
            <div style={{
              display: 'flex',
              alignItems: 'center',
              marginBottom: '20px',
              marginTop: '20px',
              marginRight: '655px'
            }}>
              {!emailCheck(Email) ? (
                <Circle check={false}/>) : (
                <Circle check={true}/>) }

              <div style={{marginLeft: '10px', marginBottom: '4px', fontFamily: 'Roboto'}}>
                {!emailCheck(Email) ? (
                  <p className={'text-mindly-pink'}>Invalid email format.</p>
                ) : (
                  <p className={'text-mindly-green'}>Valid email.</p>
                )}
              </div>
            </div>


          </div>
          <div className={'flex gap-24'}>
            <div>
              <div className={'font-roboto mb-2 ml-5'}>
                Password
              </div>
              <input
                placeholder="Enter your password."
                className="bg-white text-#CBC8C8 py-4 px-4 w-96 flex space-x-2 justify-center border-input-gray border rounded-[6.25rem] font-roboto"
                type={passwordDisplay} // Use passwordDisplay directly as the input type
                value={password}
                onChange={handlePassword}
                onFocus={() => {
                  setTimeout(() => {
                    setPasswordDisplay('password');
                  }, 500);
                }}
                onBlur={() => setPasswordDisplay('password')}
              />
            </div>
            <div>
              <div className={'font-roboto mb-2 ml-5'}>
                Confirm Password
              </div>
              <input
                placeholder="Enter your password again."
                className="bg-white text-#CBC8C8 py-4 px-4 w-96 flex space-x-2 justify-center border-input-gray border rounded-[6.25rem] font-roboto"
                type={passwordDisplay === 'text' ? 'text' : 'password'}
                value={confirmPassword}
                onChange={handlePasswordConfirm}
                onFocus={() => {
                  setTimeout(() => {
                    setPasswordDisplay('password');
                  }, 500);
                }}
                onBlur={() => setPasswordDisplay('password')}
              />
            </div>
          </div>
          <div className={'flex items-center mt-4 mr-44'}>
            <div style={{display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
              <Circle check={passwordLength(password)}/>
              <Circle check={uppercaseCheck(password)}/>
              <Circle check={lowercaseCheck(password)}/>
              <Circle check={numberCheck(password)}/>

            </div>
            <div style={{marginLeft: '10px', marginBottom: '4px', color: '#CBC8C8', fontFamily: 'Roboto'}}>
              <Text check={passwordLength(password)} text="Minimum password length is 8 characters."/>
              <Text check={uppercaseCheck(password)} text="Minimum 1 uppercase letter is required."/>
              <Text check={lowercaseCheck(password)} text="Minimum 1 lower case letter is required."/>
              <Text check={numberCheck(password)} text="Minimum 1 number is required."/>
            </div>
            <div style={{marginLeft: '120px', marginBottom: '90px', color: '#CBC8C8', fontFamily: 'Roboto'}}>
              {confirmPassword.length > 0 && confirmPassword !== password ? (
                <Circle check={false}/>
              ) : (
                <div style={{width: '100px', height: '22px'}}/>
              )}
            </div>
            <div style={{marginLeft: '12px', marginBottom: '97px', color: '#F4A5AE', fontFamily: 'Roboto'}}>
              {confirmPassword.length > 0 && confirmPassword !== password ? (
                <p className={'text-mindly-pink'}>Password doesn’t match.</p>
              ) : (
                <div style={{width: '100px', height: '16px'}}/>
              )}
            </div>

          </div>
          <p style={{color: '#87A073', fontFamily: 'Roboto', fontSize: '16px', fontWeight: 400, marginTop: '50px',}}>
            By clicking the “Sign up” button, you are creating a Mindly account and therefore you agree to Mindly’s <u>Terms
            of Use</u> and <br/> <u>Privacy Policy</u>.
          </p>
          <div className={'flex items-center mt-7 mb-10'}>
            <button style={{
              fontFamily: 'Roboto',
              width: '120px',
              height: '50px',
              borderRadius: '15px',
              textAlign: 'center',
              backgroundColor: '#CBC8C8',
              marginLeft: '20px',
              color: 'white'
            }}
                    onClick={returnToRegister}>
              Go Back
            </button>
            <button style={{
              fontFamily: 'Roboto',
              backgroundColor: '#87A073',
              width: '120px',
              height: '50px',
              borderRadius: '15px',
              textAlign: 'center',
              color: 'white',
              marginLeft: '10px'
            }}>Sign Up
            </button>
          </div>
        </form>
        <Link to={'https://projectempower.io/'}
              className="fixed bottom-5 right-7 opacity-50 font-['Roboto'] text-input-gray">
          Made by Project:Empower
        </Link>
      </div>
    </div>
  );
};

const Mobile = () => {

  return (
    <div className={'h-screen flex flex-col items-center'}>
      <img src={logo} alt={'mindly logo'} className={'w-8/12 ml-auto mr-auto mt-7'}/>
      <h1 className={'text-black text-center font-mindly-main text-4xl font-medium leading-normal mt-5'}>Register</h1>
      <form className={'mt-5 flex flex-col gap-7'}>
        <div className={'flex flex-col'}>
          <label htmlFor={'firstName'} className={'font-mindly-main text-lg font-semibold'}>First Name</label>
          <input type={'text'} id={'firstName'} className={'border border-black w-72 h-11 rounded-xl indent-3'}/>
        </div>
        <div className={'flex flex-col'}>
          <label htmlFor={'username'} className={'font-mindly-main text-lg font-semibold'}>Username</label>
          <input type={'text'} id={'username'} className={'border border-black w-72 h-11 rounded-xl indent-3'}/>
        </div>
        <div className={'flex flex-col'}>
          <label htmlFor={'email'} className={'font-mindly-main text-lg font-semibold'}>Email</label>
          <input type={'text'} id={'email'} className={'border border-black w-72 h-11 rounded-xl indent-3'}/>
        </div>
        <div className={'flex flex-col'}>
          <label htmlFor={'password'} className={'font-mindly-main text-lg font-semibold'}>Password</label>
          <input type={'text'} id={'password'} className={'border border-black w-72 h-11 rounded-xl indent-3'}/>
        </div>
        <div className={'flex flex-col'}>
          <label htmlFor={'confirmPassword'} className={'font-mindly-main text-lg font-semibold'}>Confirm
            Password</label>
          <input type={'text'} id={'confirmPassword'} className={'border border-black w-72 h-11 rounded-xl indent-3'}/>
        </div>
        <Link className={'text-#999898 mt-5 w-72 text-lg'}><u>Already have an account?</u></Link>
        <input type={'submit'}
               className={'bg-black text-white w-48 h-16 font-mindly-main mt-5 rounded-xl'}>Continue</input>
      </form>
      <Link to={'https://projectempower.io/'} className="fixed bottom-10 font-mindly-main text-xl">
        Created by Project:Empower
      </Link>
    </div>
  )
}


const EmailSignIn = () => {
  // Used to check if mobile or not
  const [width, setWidth] = useState(window.innerWidth);

  function handleWindowSizeChange() {
    setWidth(window.innerWidth);
  }

  useEffect(() => {
    window.addEventListener('resize', handleWindowSizeChange);
    return () => {
      window.removeEventListener('resize', handleWindowSizeChange);
    }
  }, []);

  const isMobile = width <= 768;

  return (
    <>
      {isMobile ? <Mobile/> : <Desktop/>}
    </>
  )
}

export default EmailSignIn;
  
