import './App.css';
import {useState, useCallback, useEffect} from 'react';
import QAndA from './pages/QAndA/QAndA';
import Calendar from './pages/Calendar/Calendar'
import Navbar from './components/Navbar/Navbar';
import Profile from './pages/Profile/Profile';
import Login from './pages/Login/Login'
import SignIn from './pages/SignIn/SignIn';
import EmailSignIn from './pages/SignIn/EmailSignIn';
import JournalLanding from "./pages/JournalLanding/JournalLanding";
import ResourceLibrary from './pages/ResourceLibrary/ResourceLibrary';
import {Routes, Route} from 'react-router-dom';
import LandingPage from './pages/LandingPage/LandingPage';
import MobileNavbar from './components/MobileNavbar/MobileNavbar';
import MyJournalMobile from './pages/MyJournalMobile/MyJournalMobile';
import NewEntry from './pages/NewEntry/NewEntry';
import BookmarksMobile from './pages/BookmarksMobile/BookmarksMobile';
import MyJournalCategorized from './pages/MyJournal/MyJournalCategorized';
import Journal from './pages/Journal/Journal';
import Setup from "./pages/Setup/Setup";
import CheckLogin from "./lib/CheckLogin";
import RequireAuth from "./components/AuthTools/RequireAuth";
import AuthRedirect from "./components/AuthTools/AuthRedirect";


let position = false;
function App() {
  const [toggle, setToggle ] = useState(true);
  const handleToggle = useCallback(() => {setToggle(prevToggle => !prevToggle)
  position = !position
  },[])


  return (
    <div className="App">
      <header className="App-header">
            <Routes>

              {/* When adding your component please add a path
              name to the to parameter and add the name of your compenent
              to the element parameter and import your component at the top*/}

              {/*// README: The RequireAuth component is used to check if the user is logged in. Redirects to "/" if not logged in*/}
              {/*// README: The AuthRedirect component is used to check if the user is logged in. Redirects to "/home" if logged in*/}
        
                <Route path="/qanda" element={<RequireAuth><QAndA/></RequireAuth>}/>
                <Route path="/" element={<AuthRedirect><LandingPage/></AuthRedirect>}/>
                <Route path={"/home"} element={
                  <RequireAuth>
                     <JournalLanding/>
                  </RequireAuth>
                }/>
                <Route path="/profile" element={<RequireAuth><Profile/></RequireAuth>}/>
                <Route path="/register" element={<AuthRedirect><SignIn/></AuthRedirect>}/>
                <Route path="/register/email" element={<AuthRedirect><EmailSignIn/></AuthRedirect>}/>
                <Route path="/login/*" element={<AuthRedirect><Login/></AuthRedirect>}/>
                {/*README: There is a route /login/email within the Login component*/}
                <Route path="/resourcelibrary" element={<ResourceLibrary />}/>
                <Route path="/myjournalmobile" element={<RequireAuth><MyJournalMobile/></RequireAuth>}/>
                <Route path="/newentry" element={<RequireAuth><NewEntry/></RequireAuth>}/>
                <Route path="/calendar" element={<Calendar />}/>
                <Route path="/bookmarksmobile" element={<RequireAuth><BookmarksMobile/></RequireAuth>}/>
                <Route path="/MyJournal/Categorized" element={<RequireAuth><MyJournalCategorized/></RequireAuth>}/>
                <Route path="/journal" element={<RequireAuth><Journal/></RequireAuth>}/>
                <Route path={"/setup"} element={<Setup/>}/>
            </Routes>

      </header>
    </div>
  );
}

export default App;