const Text = ({ check, text }) => {
    let color;

    if (check === true) {
      color = '#87A073';
    } else if (check === false) {
      color = '#F4A5AE';
    } else {
      color = '#CBC8C8'; // Default gray color
    }
    
    return (
        <p style={{ marginBottom: '5px', color }}>{text}</p>
    );
}

export default Text;
