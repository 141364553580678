import React from "react";


const port = process.env.REACT_APP_BACKEND_PORT

// Functions to verify input validity

export const specialCharacters = (text) => text.length === 0 ? null : /^[a-zA-Z0-9]+$/.test(text);
export const charLimit = (text) => text.length === 0 ? null : text.length <= 32;
export const emailCheck = (text) => text.length > 0 && /\S+@\S+\.\S+/.test(text)
export const passwordLength = (text) => text.length === 0 ? null : text.length >= 8
export const uppercaseCheck = (text) => text.length === 0 ? null : /[A-Z]/.test(text)
export const lowercaseCheck = (text) => text.length === 0 ? null : /[a-z]/.test(text)
export const numberCheck = (text) => text.length === 0 ? null : /[0-9]/.test(text)

const getDate = () => {
  const currentDate = new Date();
  return currentDate.toISOString();
};


export const createAccount = async (username, firstname, email, password, confpassword) => {


  const verifyUser = () => {
    return specialCharacters(username) && charLimit(username)
  }

  const verifyFirstname = () => {
    return specialCharacters(firstname) && charLimit(firstname)
  }

  const verifyEmail = () => {

    return emailCheck(email)
  }

  const verifyPassword = () => {
    return passwordLength(password) && uppercaseCheck(password) && lowercaseCheck(password) && numberCheck(password)
  }

  const confirmPassword = () => {
    return password === confpassword
  }

  if (verifyUser() && verifyFirstname() && verifyEmail() && verifyPassword() && confirmPassword()) {

    const data = {
      username: username,
      name: firstname,
      email: email,
      password: password,
      registrationDate: getDate(),
      registrationMethod: 'system',
    }

    try {
      const response = await fetch(`http://localhost:${port}/api/auth/signup`, {
        method: 'POST',
        mode: 'cors',
        credentials: 'include',
        headers: {
          'Content-Type': 'application/json',

        },
        body: JSON.stringify(data)

      });


      if (!response.ok) {
        throw new Error('Network response was not ok');
      }


      return await response.json();

    } catch (error) {
      console.log(error)
      // throw error
    }
  } else {
    return "invalid"
  }

}
