import React from 'react'
import { useState } from 'react';
import icons  from '../../assets/entryfromicons.png'
import './NewEntry.css'
import Navbar from "../../components/Navbar/Navbar";
import Cookies from "universal-cookie";

const NewEntry = () => {
  const port = process.env.REACT_APP_BACKEND_PORT
  const cookies = new Cookies();
  const userId = cookies.get('uuid');
    const [content, setContent] = useState("");
    const [title, setTitle] = useState("");

    const submitForm = (e) => {
    e.preventDefault();
    const entryInfo = {
        userId: `${userId}`,
        title: title,
        content: content,
        sentiment: "Happy" //UI team needs to add this to form design
    }
    const entryData = JSON.stringify(entryInfo);

    //POSTS the entry to the backend which will render back on my Journal Mobile 
    fetch(`http://localhost:${port}/api/journal/${userId}`, {
        method: 'POST',
        mode: "cors", 
        credentials: 'include',
        headers: {
          'Content-Type': 'application/json',
        },
        body: entryData,
      })
      .then(response => {
        return response.json();
      })
      .then(responseData => {
        console.log('Server response:', responseData);
      })
      .catch(error => {
        console.error('Fetch error:', error);
      });
}

  return (
    <>
      <Navbar backgroundColorHover={true}/>
      <div className='NewEntry'>
          <h1 className='newentryheader'>New Entry</h1>
          <form className='entryform' onSubmit={submitForm}>
              <input 
                  type='text' 
                  placeholder='Edit Title' 
                  value={title}  
                  onChange={(e)=> setTitle(e.target.value)}
              />
              <textarea 
                  type="text" 
                  placeholder='Type Here...' 
                  id="journalContent"
                  value={content} 
                  onChange={(e)=> setContent(e.target.value)}
              />
              <div className='entryformicons'>
                <img src={icons} alt='entryformicons' />
              </div>
              <button className={'newentrybutton'} type="submit">Submit</button>
          </form>
      </div>
    </>
  )
}

export default NewEntry