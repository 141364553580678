import React from 'react';
import DateFormat from "../../pages/JournalLanding/lib/DateFormat";

const EmptyJournalCard = () => {
  return (
    <div className=''>
      <p className='font-roboto text-mindly-green text mt-12'>{DateFormat()}</p>
      <button className='text-mindly-gray my-2 border border-dashed border-mindly-gray w-60 h-60 rounded-2xl'>
        <p className={'text-input-gray text-center'}>No journals yet.<br/>Start capturing your thoughts!</p>
      </button>
    </div>
  );
};

export default EmptyJournalCard;