import React, {useCallback, useEffect, useState} from 'react';
import Cookies from "universal-cookie";
import {useNavigate} from "react-router";

import Navbar from "../../components/Navbar/Navbar";
import CalendarPopup from '../../components/CalendarPopup/CalendarPopup';
import JournalCard from "../../components/JournalCard/JournalCard";
import EmptyJournalCard from "../../components/JournalCard/EmptyJournalCard";
import MobileNavbar from '../../components/MobileNavbar/MobileNavbar';
import Sidenav from '../../components/Sidenav/Sidenav';

import calendar from '../../assets/calendar.svg'
import './journalLanding.css'

import GetJournals from "./lib/GetJournals";
import DateFormat from "./lib/DateFormat";
import GetUserInfo from "../../lib/GetUserInfo";

const JournalLanding = () => {

  const port = process.env.REACT_APP_BACKEND_PORT
  const cookies = new Cookies()
  const navigate = useNavigate()
  const [name, setName] = useState('')
  const [accountValid, setValidity] = useState(false)
  const [journals, setJournals] = useState()
  const [loading, setLoading] = useState(true)


  //Navbar functions
  const [toggle, setToggle] = useState(true);
  const handleToggle = useCallback(() => setToggle(prevToggle => !prevToggle), [])


  const getMonthYear = () => {
    const months = [
      'January', 'February', 'March', 'April', 'May', 'June',
      'July', 'August', 'September', 'October', 'November', 'December'
    ];

    const currentDate = new Date();
    const month = months[currentDate.getMonth()];
    const year = currentDate.getFullYear();

    return `${month} ${year}`;
  };
  const [calendarState, setCalendarState] = useState(false);
  const calendarShow = () => {
    setCalendarState(!calendarState);
  }

  useEffect(() => {
    const getName = async () => {
      const userInfo = await GetUserInfo()
      setName(userInfo['name'])
    }
    getName()

    const getAllJournals = async () => {
      const journalData = await GetJournals()
      setJournals(journalData)

      setLoading(false)
    }
    getAllJournals()


  }, [])

  return (
    <>
      <Navbar onToggle={handleToggle} backgroundColorHover={true}/>
      <MobileNavbar toggle={toggle}/>
      <Sidenav/>
      <section>
        <div className='container flex-col justify-center items-center text-center mx-auto my-8'>
          <p className='text-mindly-green text-lg font-roboto leading-normal'>{DateFormat()}</p>

          <h1 className='text-black text-center font-mindly-main text-3xl font-medium leading-normal mb-8'>Hello, {name !== null ? name : 'Loading...'}</h1>

          <button
            onClick={() => navigate('/journal')}
            className='w-56 h-12 text-black font-roboto text-base rounded-2xl border border-mindly-black ease-in-out duration-300 button'>
            Start Today's Journal!
          </button>
        </div>
        <hr className="mx-20 h-px bg-input-gray my-8"/>
        <div className='flex flex-row justify-between px-20'>
          <h1 className='mx-50 text-black font-mindly-main text-4xl'>{getMonthYear()}</h1>
          <div className='circlehov'>
            <img onClick={calendarShow} src={calendar} alt={'calendar'}/>
          </div>
          <CalendarPopup show={calendarState} setShow={setCalendarState}/>
        </div>
        <div className={'flex flex-wrap px-20 gap-20'}>
          {journals ? (
            journals.length === 0 ? (
              <EmptyJournalCard/>
            ) : (
              journals.map((journal) => (
                <JournalCard title={journal.title} mood={journal.sentiment}/>
              ))
            )
          ) : <div>Loading...</div>}
        </div>
      </section>
    </>
  );
};

export default JournalLanding;