import React from 'react'
import './MyJournalMobile.css'
import JournalListBox from './JournalListBox'
import { formatEntryDate, setAllJournalDataDates } from './MyJournalHelpers'
import calendar from '../../assets/calendar.svg'
import { useState, useEffect } from 'react'
import Cookies from "universal-cookie";

const MyJournalMobile = () => {
  const port = process.env.REACT_APP_BACKEND_PORT
  const cookies = new Cookies();
  const userId = cookies.get('uuid');

  const [user, setUser] = useState(null);
  const [journalData, setJournalData] = useState([])
  const [entriesData, setEntriesData] = useState([]);
  const [currentMonth, setCurrentMonth] = useState("");
  const [currentYear, setCurrentYear] = useState("");

  let alreadyRan = false;
  useEffect(() => {
    if (!alreadyRan) {
      const currentJournalData = setAllJournalDataDates(setCurrentMonth, setCurrentYear, setJournalData);
      
      //fetch user -- /api/users/:userId, example: `http://localhost:5001/api/users/64d469c743b6a0a7b7894bcd`
      fetch(`http://localhost:${port}/api/users/${userId}`, {
        method: "GET",
        mode: "cors", 
        credentials: 'include',
        headers: {
          "Content-Type": "application/json",
        },
      })
        .then(data => data.json())
        .then(data => {
          const newUser = {
            id: data["uuid"],
            name: data.name,
          };
          setUser(newUser);
        })
        .catch(e => console.log(e))

      //fetch all of a user's journal entries -- /api/journal/all/:userId, example: http://localhost:5000/api/journal/all/64d469c743b6a0a7b7894bcd
      fetch(`http://localhost:${port}/api/journal/all/${userId}`, {
        method: "GET",
        mode: "cors", 
        credentials: 'include',
        headers: {
          "Content-Type": "application/json",
        },
      })
        .then(data => data.json())
        .then(data => {
          data.map((ent) => {
            const newEntry = {
              id: ent["uuid"],
              title: ent.title,
              emotion: ent.sentiment,
              content: ent.content,
              date: ent.entryDate.substring(0, 10)
            };
              //sorts each entry into the proper journalListBox date
              setEntriesData(prevEntries => [...prevEntries, newEntry]);
              const currentEntriesData = [...entriesData, newEntry];
              currentEntriesData.map((entry) => {
                currentJournalData.map((journalBox) => {
                  if (journalBox.name === formatEntryDate(entry.date)) {
                    journalBox.journalEntriesData.push(entry);
                  } 
                })
              })
            })
          })
          .catch(e => console.log(e))
          alreadyRan = true;
    }
  }, []
  );

  const hasAnyEntries = journalData.some(journalBox => journalBox.journalEntriesData.length > 0);

  return (
    <div className='myJournalMobile'>
        <h1 className='EB'>Hello, <span>{user!==null ? user.name : 'Username'}!</span></h1>
        <div className='monthAndYear'>
            <h2 className='EB'>{currentMonth} {currentYear}</h2>
            <img src={calendar} alt="calendar"/>
        </div>
        {
          hasAnyEntries ? 
            journalData.map(journalBox => {
                return journalBox.journalEntriesData.length > 0 && <JournalListBox name={journalBox.name} journalEntriesData={journalBox.journalEntriesData}/>
            }) 
            : 
            (
            <div className='noJournals'>
              <h1>No journals yet.</h1>
              <h3>Start capturing your thoughts!</h3>
            </div>
            )
        } 
        {/* needs an onClick that routes it to the new entry page */}
        <button className='bottomButton'>Start Today's Journal!</button>
    </div>
  )
}

export default MyJournalMobile