import React from 'react'
import search from '../../assets/searchIcon.svg'
import {Link} from 'react-router-dom';
import {useState, useCallback} from 'react';
import Navbar from '../../components/Navbar/Navbar';
import MobileNavbar from '../../components/MobileNavbar/MobileNavbar';
import Sidenav from '../../components/Sidenav/Sidenav';
const QAndA = () => {
    //Navbar functions
    const [toggle, setToggle ] = useState(true);
    const handleToggle = useCallback(() => setToggle(prevToggle => !prevToggle),[])
  return (
    
   <section className="font-mindly-main flex flex-col">

    <Navbar onToggle={handleToggle} backgroundColorHover={true}/>
    <MobileNavbar toggle={toggle}/>
    <Sidenav />
    
    <h1 className="text-center text-5xl">Frequently Asked Questions</h1>
    <div className="w-fit flex m-12 ml-auto mr-auto">
    <input className ="rounded-3xl p-2 w-96 border-2 border-zinc-400 focus:none" placeholder="Search for..." type="text"></input>
    <img alt="search button" className="bg-slate-950 p-2 ml-1 rounded-full" src={search} />
    </div>
    <hr className="bg-slate-500"/>
<div className="flex w-full">
  <div className="w-3/5">
    <div className="flex p-6 w-fit font-roboto">
      <h3 onClick={() => window.location.replace("/qanda/#general")} className="p-2 px-5 text-xl rounded-3xl whitespace-nowrap border-2 border-zinc-400 m-4 hover:bg-mindly-green hover:text-white">General</h3>
      <h3 onClick={() => window.location.replace("/qanda/#how-to-use")} className="p-2 px-5 text-xl rounded-3xl whitespace-nowrap border-2 border-zinc-400 m-4 hover:bg-mindly-green hover:text-white">How to use</h3>
     <h3 onClick={() => window.location.replace("/qanda/#synchronization")} className="p-2 px-5 text-xl rounded-3xl whitespace-nowrap border-2 border-zinc-400 m-4 hover:bg-mindly-green hover:text-white">Synchronization</h3>
      <h3 onClick={() => window.location.replace("/qanda/#privacy")} className="p-2 px-5 text-xl rounded-3xl whitespace-nowrap border-2 border-zinc-400 m-4 hover:bg-mindly-green hover:text-white">Privacy</h3>
    </div>
    <div id="general" className="my-8 px-6 w-full">
      <h2 className="text-4xl font-semibold">General</h2>
      <div className="my-3">
                <div className="flex font-roboto text-xl">
                    <h1 className="p-1 pr-4 text-3xl text-emerald-500">Q</h1>
                    <h3 className="p-1 pl-4 font-medium">How do I create a new journal? </h3>
                </div>
                <div className="flex font-roboto text-xl">
                    <h1 className="p-1 pr-4 text-3xl text-emerald-500">A</h1>
                    <div className="p-1 pl-4">
                        Lorem ipsum dolor sit amet, consectetur adipiscing elit, 
                        sed do eiusmod tempor incididunt ut labore et dolore magna 
                        aliqua. Ut enim ad minim veniam, quis nostrud exercitation 
                        ullamco laboris nisi ut aliquip ex ea commodo consequat. 
                        Duis aute irure dolor in reprehenderit in voluptate velit 
                        esse cillum dolore eu fugiat nulla pariatur. Excepteur sint 
                        occaecat cupidatat non proident, sunt in culpa qui officia 
                        deserunt mollit anim id est laborum.
                    </div>
                </div>
            </div>
    
    <hr className="my-6"/>
    <div className="my-3">
                <div className="flex font-roboto text-xl">
                    <h1 className="p-1 pr-4 text-3xl text-emerald-500">Q</h1>
                    <h3 className="p-1 pl-4 font-medium">Question 02</h3>
                </div>
                <div className="flex font-roboto text-xl">
                    <h1 className="p-1 pr-4 text-3xl text-emerald-500">A</h1>
                    <div className="p-1 pl-4">
                        Lorem ipsum dolor sit amet, consectetur adipiscing elit, 
                        sed do eiusmod tempor incididunt ut labore et dolore magna 
                        aliqua. Ut enim ad minim veniam, quis nostrud exercitation 
                        ullamco laboris nisi ut aliquip ex ea commodo consequat. 
                    </div>
                </div>
            </div>
            <hr className="my-6"/>


            <div className="my-3">
                <div className="flex font-roboto text-xl">
                    <h1 className="p-1 pr-4 text-3xl text-emerald-500">Q</h1>
                    <h3 className="p-1 pl-4 font-medium">Question 3</h3>
                </div>
                <div className="flex font-roboto text-xl">
                    <h1 className="p-1 pr-4 text-3xl text-emerald-500">A</h1>
                    <div className="p-1 pl-4">
                        Lorem ipsum dolor sit amet, consectetur adipiscing elit, 
                        sed do eiusmod tempor incididunt ut labore et dolore magna 
                        aliqua. 
                    </div>
                </div>
            </div>
            <hr className="my-6"/>


            <div className="my-3">
                <div className="flex font-roboto text-xl">
                    <h1 className="p-1 pr-4 text-3xl text-emerald-500">Q</h1>
                    <h3 className="p-1 pl-4 font-medium">Question 04</h3>
                </div>
                <div className="flex font-roboto text-xl">
                    <h1 className="p-1 pr-4 text-3xl text-emerald-500">A</h1>
                    <div className="p-1 pl-4">
                        Lorem ipsum dolor sit amet, consectetur adipiscing elit, 
                        sed do eiusmod tempor incididunt ut labore et dolore magna 
                        aliqua. Ut enim ad minim veniam.
                    </div>
                </div>
            </div>


        
          </div>

    <div id="how-to-use" className="my-8 px-6">
      <h2 className="text-4xl font-semibold">How to use</h2>
      <div className="my-3">
                <div className="flex font-roboto text-xl">
                    <h1 className="p-1 pr-4 text-3xl text-emerald-500">Q</h1>
                    <h3 className="p-1 pl-4 font-medium">How do I create a new journal? </h3>
                </div>
                <div className="flex font-roboto text-xl">
                    <h1 className="p-1 pr-4 text-3xl text-emerald-500">A</h1>
                    <div className="p-1 pl-4">
                        Lorem ipsum dolor sit amet, consectetur adipiscing elit, 
                        sed do eiusmod tempor incididunt ut labore et dolore magna 
                        aliqua. Ut enim ad minim veniam, quis nostrud exercitation 
                        ullamco laboris nisi ut aliquip ex ea commodo consequat. 
                        Duis aute irure dolor in reprehenderit in voluptate velit 
                        esse cillum dolore eu fugiat nulla pariatur. Excepteur sint 
                        occaecat cupidatat non proident, sunt in culpa qui officia 
                        deserunt mollit anim id est laborum.
                    </div>
                </div>
            </div>
    
    <hr className="my-6"/>
    <div className="my-3">
                <div className="flex font-roboto text-xl">
                    <h1 className="p-1 pr-4 text-3xl text-emerald-500">Q</h1>
                    <h3 className="p-1 pl-4 font-medium">Question 02</h3>
                </div>
                <div className="flex font-roboto text-xl">
                    <h1 className="p-1 pr-4 text-3xl text-emerald-500">A</h1>
                    <div className="p-1 pl-4">
                        Lorem ipsum dolor sit amet, consectetur adipiscing elit, 
                        sed do eiusmod tempor incididunt ut labore et dolore magna 
                        aliqua. Ut enim ad minim veniam, quis nostrud exercitation 
                        ullamco laboris nisi ut aliquip ex ea commodo consequat. 
                    </div>
                </div>
            </div>
            <hr className="my-6"/>


            <div className="my-3">
                <div className="flex font-roboto text-xl">
                    <h1 className="p-1 pr-4 text-3xl text-emerald-500">Q</h1>
                    <h3 className="p-1 pl-4 font-medium">Question 3</h3>
                </div>
                <div className="flex font-roboto text-xl">
                    <h1 className="p-1 pr-4 text-3xl text-emerald-500">A</h1>
                    <div className="p-1 pl-4">
                        Lorem ipsum dolor sit amet, consectetur adipiscing elit, 
                        sed do eiusmod tempor incididunt ut labore et dolore magna 
                        aliqua. 
                    </div>
                </div>
            </div>
            <hr className="my-6"/>


            <div className="my-3">
                <div className="flex font-roboto text-xl">
                    <h1 className="p-1 pr-4 text-3xl text-emerald-500">Q</h1>
                    <h3 className="p-1 pl-4 font-medium">Question 04</h3>
                </div>
                <div className="flex font-roboto text-xl">
                    <h1 className="p-1 pr-4 text-3xl text-emerald-500">A</h1>
                    <div className="p-1 pl-4">
                        Lorem ipsum dolor sit amet, consectetur adipiscing elit, 
                        sed do eiusmod tempor incididunt ut labore et dolore magna 
                        aliqua. Ut enim ad minim veniam.
                    </div>
                </div>
            </div>
            


        
    </div>
    <div id="synchronization" className="my-8 px-6">
      <h2 className="text-4xl font-semibold">Synchronization</h2>
      <div className="my-3">
                <div className="flex font-roboto text-xl">
                    <h1 className="p-1 pr-4 text-3xl text-emerald-500">Q</h1>
                    <h3 className="p-1 pl-4 font-medium">How do I create a new journal? </h3>
                </div>
                <div className="flex font-roboto text-xl">
                    <h1 className="p-1 pr-4 text-3xl text-emerald-500">A</h1>
                    <div className="p-1 pl-4">
                        Lorem ipsum dolor sit amet, consectetur adipiscing elit, 
                        sed do eiusmod tempor incididunt ut labore et dolore magna 
                        aliqua. Ut enim ad minim veniam, quis nostrud exercitation 
                        ullamco laboris nisi ut aliquip ex ea commodo consequat. 
                        Duis aute irure dolor in reprehenderit in voluptate velit 
                        esse cillum dolore eu fugiat nulla pariatur. Excepteur sint 
                        occaecat cupidatat non proident, sunt in culpa qui officia 
                        deserunt mollit anim id est laborum.
                    </div>
                </div>
            </div>
    
    <hr className="my-6"/>
    <div className="my-3">
                <div className="flex font-roboto text-xl">
                    <h1 className="p-1 pr-4 text-3xl text-emerald-500">Q</h1>
                    <h3 className="p-1 pl-4 font-medium">Question 02</h3>
                </div>
                <div className="flex font-roboto text-xl">
                    <h1 className="p-1 pr-4 text-3xl text-emerald-500">A</h1>
                    <div className="p-1 pl-4">
                        Lorem ipsum dolor sit amet, consectetur adipiscing elit, 
                        sed do eiusmod tempor incididunt ut labore et dolore magna 
                        aliqua. Ut enim ad minim veniam, quis nostrud exercitation 
                        ullamco laboris nisi ut aliquip ex ea commodo consequat. 
                    </div>
                </div>
            </div>
            <hr className="my-6"/>


            <div className="my-3">
                <div className="flex font-roboto text-xl">
                    <h1 className="p-1 pr-4 text-3xl text-emerald-500">Q</h1>
                    <h3 className="p-1 pl-4 font-medium">Question 3</h3>
                </div>
                <div className="flex font-roboto text-xl">
                    <h1 className="p-1 pr-4 text-3xl text-emerald-500">A</h1>
                    <div className="p-1 pl-4">
                        Lorem ipsum dolor sit amet, consectetur adipiscing elit, 
                        sed do eiusmod tempor incididunt ut labore et dolore magna 
                        aliqua. 
                    </div>
                </div>
            </div>
            <hr className="my-6"/>


            <div className="my-3">
                <div className="flex font-roboto text-xl">
                    <h1 className="p-1 pr-4 text-3xl text-emerald-500">Q</h1>
                    <h3 className="p-1 pl-4 font-medium">Question 04</h3>
                </div>
                <div className="flex font-roboto text-xl">
                    <h1 className="p-1 pr-4 text-3xl text-emerald-500">A</h1>
                    <div className="p-1 pl-4">
                        Lorem ipsum dolor sit amet, consectetur adipiscing elit, 
                        sed do eiusmod tempor incididunt ut labore et dolore magna 
                        aliqua. Ut enim ad minim veniam.
                    </div>
                </div>
            </div>
            


        
          </div>
          <div id="privacy" className="my-8 px-6">
      <h2 className="text-4xl font-semibold">Privacy</h2>
      <div className="my-3">
                <div className="flex font-roboto text-xl">
                    <h1 className="p-1 pr-4 text-3xl text-emerald-500">Q</h1>
                    <h3 className="p-1 pl-4 font-medium">How do I create a new journal? </h3>
                </div>
                <div className="flex font-roboto text-xl">
                    <h1 className="p-1 pr-4 text-3xl text-emerald-500">A</h1>
                    <div className="p-1 pl-4">
                        Lorem ipsum dolor sit amet, consectetur adipiscing elit, 
                        sed do eiusmod tempor incididunt ut labore et dolore magna 
                        aliqua. Ut enim ad minim veniam, quis nostrud exercitation 
                        ullamco laboris nisi ut aliquip ex ea commodo consequat. 
                        Duis aute irure dolor in reprehenderit in voluptate velit 
                        esse cillum dolore eu fugiat nulla pariatur. Excepteur sint 
                        occaecat cupidatat non proident, sunt in culpa qui officia 
                        deserunt mollit anim id est laborum.
                    </div>
                </div>
            </div>
    
    <hr className="my-6"/>
    <div className="my-3">
                <div className="flex font-roboto text-xl">
                    <h1 className="p-1 pr-4 text-3xl text-emerald-500">Q</h1>
                    <h3 className="p-1 pl-4 font-medium">Question 02</h3>
                </div>
                <div className="flex font-roboto text-xl">
                    <h1 className="p-1 pr-4 text-3xl text-emerald-500">A</h1>
                    <div className="p-1 pl-4">
                        Lorem ipsum dolor sit amet, consectetur adipiscing elit, 
                        sed do eiusmod tempor incididunt ut labore et dolore magna 
                        aliqua. Ut enim ad minim veniam, quis nostrud exercitation 
                        ullamco laboris nisi ut aliquip ex ea commodo consequat. 
                    </div>
                </div>
            </div>
            <hr className="my-6"/>


            <div className="my-3">
                <div className="flex font-roboto text-xl">
                    <h1 className="p-1 pr-4 text-3xl text-emerald-500">Q</h1>
                    <h3 className="p-1 pl-4 font-medium">Question 3</h3>
                </div>
                <div className="flex font-roboto text-xl">
                    <h1 className="p-1 pr-4 text-3xl text-emerald-500">A</h1>
                    <div className="p-1 pl-4">
                        Lorem ipsum dolor sit amet, consectetur adipiscing elit, 
                        sed do eiusmod tempor incididunt ut labore et dolore magna 
                        aliqua. 
                    </div>
                </div>
            </div>
            <hr className="my-6"/>


            <div className="my-3">
                <div className="flex font-roboto text-xl">
                    <h1 className="p-1 pr-4 text-3xl text-emerald-500">Q</h1>
                    <h3 className="p-1 pl-4 font-medium">Question 04</h3>
                </div>
                <div className="flex font-roboto text-xl">
                    <h1 className="p-1 pr-4 text-3xl text-emerald-500">A</h1>
                    <div className="p-1 pl-4">
                        Lorem ipsum dolor sit amet, consectetur adipiscing elit, 
                        sed do eiusmod tempor incididunt ut labore et dolore magna 
                        aliqua. Ut enim ad minim veniam.
                    </div>
                </div>
            </div>
           


        
          </div>

          
    </div>
                      <div className="fixed inset-y-25 right-0 font-roboto text-white rounded-xl mt-48 mr-12 p-8 w-4/12 h-fit  bg-mindly-green">
                        <h3 className="font-medium">
                            <bold>Can’t find the help you’re looking for?</bold>
                        </h3>
                        <br />
                        <p className="">
                        Feel free to email us!
                        </p>
                        <p className="">
                        We'll get back to you within 2-3 business days.
                        </p>
                        <br />
                        <Link to="mailto:mindlysupport@projectempower.io"> <p className="whitespace-normal">Email: mindlysupport @ projectempower.io
                        </p> </Link>
                    </div>

  </div>
    
    
    
    
   </section>
  )
}

export default QAndA;