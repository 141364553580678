import React from 'react';
import Nav from '../../assets/nav.png';
import MindlyLogo from '../../assets/mindly-logo.png';
import Profile from '../../assets/profile.png';

const MyJournalCategorized = () => {
    return (
        <div>
            <div className={'flex flex-row justify-between p-8'}>
                <img className={'w-6 h-3.5'} src={Nav} />
                <img className={'w-40 h-8'} src={MindlyLogo} />
                <img className={'w-4 h-5'} src={Profile} />
            </div>
            <h1 className={'text-center font-mindly-main font-semibold p-10 mx-14 divide-solid border-input-gray border-b'}>My Journals</h1>
            <div className={'flex flex-row space-x-96'}>
                <h1 className={'font-mindly-main font-semibold text-xl ml-14 py-6'}>Travel</h1>
                <h1 className={'font-mindly-main font-semibold text-xl py-6'}>Meditation</h1>
                <h1 className={'font-mindly-main font-semibold text-xl py-6'}>Events</h1>
            </div>
        </div>
    );
};

export default MyJournalCategorized;