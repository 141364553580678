import React from 'react'
import JournalEntry from './JournalEntry'
import { useState } from 'react'


const JournalListBox = ({ name, journalEntriesData, style }) => {

  function toggleExpansion() {
    setIsExpanded(!isExpanded)
  };

  const [isExpanded, setIsExpanded] = useState(false)
  return (
    <div className='journalListBox' style={style}>
      <div className='JournalListBoxHead'>
          <span>{name}</span>
          <button className='seeMore' onClick={toggleExpansion}>{isExpanded ? 'SEE MORE' : 'SEE LESS'}</button>
      </div>
      {journalEntriesData.slice(0, isExpanded ? undefined : 2).map((entry) => {
      return <JournalEntry journalTitle={entry.title} emotion={entry.emotion} key={entry['_id']} />;
    })}
    </div>
  )
}

export default JournalListBox