import React from 'react';
import DateFormat from "../../pages/JournalLanding/lib/DateFormat";
import placeholder from "../../assets/journal-placeholder.jpeg"


const JournalCard = (props) => {
  return (
    <div className=''>
      <p className='font-roboto text-mindly-green text mt-12'>{DateFormat()}</p>
      <button className='text-mindly-gray my-2 w-60 h-60 rounded-2xl overflow-hidden shadow-md shadow-gray-300 flex flex-col'>
        <div className={'overflow-hidden rounded-t-2xl mb-2 w-full'}>
          <img src={placeholder} />
        </div>
        <div>
          <h2 className={'font-roboto text-black text-xl px-5 overflow-hidden max-w-60 whitespace-nowrap text-ellipsis'}>{props.title}</h2>
          <p className={'font-roboto text-gray-500 text-sm px-5 font-thin overflow-hidden max-w-60 whitespace-nowrap text-ellipsis'}>{props.mood}</p>
        </div>
      </button>
    </div>
  );
};

export default JournalCard;