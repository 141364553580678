import React from 'react'

const LandingPageTitle = () => {
  return (
    <div className="landingPage_title">
        <h1>Project:Mindly</h1>
        <p>Capture your thoughts, unleash your creativity, and explore the depths of your mind with Mindly.</p>
    </div>  
    )
}

export default LandingPageTitle