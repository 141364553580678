import React from 'react';

const Resource = ({ title, description, isBook }) => {
  return (
    <div className='resource'>
      {isBook ? (
        <span>{title}</span>
      ) : (
        <>
          <span style={{ textDecoration: 'underline' }}>{title}</span>
          <span>: {description}</span>
        </>
      )}
    </div>
  );
};

export default Resource;
