import React from 'react'
import search from '../../assets/searchIcon.svg'
import {Link} from 'react-router-dom';
import './Calendar.css'
import backarrow from '../../assets/backarrow.svg';
import downarrow from '../../assets/downarrow.svg';
import Navbar from "../../components/Navbar/Navbar";



const Calendar = () => {
  return (
    <>
        <Navbar backgroundColorHover={true}/>
        <section className="month">
        <div id="backarrow"><img src={backarrow}/></div>
        <div id="month-wrapper">
            <div className="fonts month-title">August 2023</div>
            <div id="arrow-img"><img src={downarrow}/></div>
        </div>
        <div className='fonts'>
            <div className="space">
                <div className='row'>
                    <div className='column'>
                        <div className='date-font'>
                            S
                        </div>
                    </div>
                    <div className='column'>
                        <div className='date-font'>
                            M
                        </div>
                    </div>
                    <div className='column'>
                        <div className='date-font'>
                            T
                        </div>
                    </div>
                    <div className='column'>
                        <div className='date-font'>
                            W
                        </div>
                    </div>
                    <div className='column'>
                        <div className='date-font'>
                            T
                        </div>
                    </div>
                    <div className='column'>
                        <div className='date-font'>
                            F
                        </div>
                    </div>
                    <div className='column'>
                        <div className='date-font'>
                            S
                        </div>
                    </div>
                </div>
                <div className='row'>
                    <div className='column'>
                        <div className='num-font day'>
                            4
                        </div>
                    </div>
                    <div className='column'>
                        <div className='num-font day'>
                            5
                        </div>
                    </div>
                    <div id="today" className='column'>
                        <div className='num-font day'>
                            6
                        </div>
                    </div>
                    <div className='column'>
                        <div className='num-font day'>
                            7
                        </div>
                    </div>
                    <div className='column'>
                        <div className='num-font day'>
                            8
                        </div>
                    </div>
                    <div className='column'>
                        <div className='num-font day'>
                            9
                        </div>
                    </div>
                    <div className='column'>
                        <div className='num-font day'>
                            10
                        </div>
                    </div>
                </div>
            </div>
            <div>
            <hr/>
            </div>
            <div className="journals">
                <p>Journal Title #1</p>
            </div>
            <div className="journals"> 
                <p>Journal Title #2</p>
            </div>
            <div className="journals">
                <p>Journal Title #3</p>
            </div>

        </div>
    </section>
   </>

  )
}

export default Calendar;