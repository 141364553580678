import React from 'react'
import ResourceBox from './ResourceBox'
import './ResourceLibrary.css'
import { articlesAndBlogs } from './ResourceData'
import { tedtalks } from './ResourceData'
import { podcasts } from './ResourceData'
import { books } from './ResourceData'

const ResourceLibrary = () => {

//     const books = [
//         {
//             title: "\"Lost Connections: Uncovering the Real Causes of Depression—and the Unexpected Solutions\" by Johann Hari.
// "The Body Keeps the Score: Brain, Mind, and Body in the Healing of Trauma" by Bessel van der Kolk.
// "The Anxiety and Phobia Workbook" by Edmund J. Bourne.
// "The Gifts of Imperfection" by Brené Brown.
//     ]
  return (
    <div className='resourcelibrary'>
        <h1 className='mainTitle'>Resource Library</h1>
        <div className='main'>
            <ResourceBox resourceTitle="Article and Blogs" data={articlesAndBlogs} />
            <ResourceBox resourceTitle="Ted Talks and Videos" data={tedtalks} />
            <ResourceBox resourceTitle="Podcasts" data={podcasts} />
            <ResourceBox resourceTitle="Books" data={books} />
        </div>
    </div>
  )
}

export default ResourceLibrary