import { Navigate } from "react-router";
import { useState } from "react";
import CheckLogin from "../../lib/CheckLogin";

const AuthRedirect = ({ children }) => {
  const [userIsLogged, setUserIsLogged] = useState(null);

  // Call CheckLogin directly when the component renders
  CheckLogin().then(result => {
    setUserIsLogged(result);
  }).catch(error => {
    console.error("Error checking login:", error);
    setUserIsLogged(false); // Assume user is not logged in if there's an error
  });


  if (userIsLogged === null) {
    return <div>Loading...</div>;
  } else if (userIsLogged) {
    return <Navigate to={"/home"} replace={true} />;
  } else {
    return children;
  }
};

export default AuthRedirect;
