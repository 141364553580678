import React from 'react'
import search from '../../assets/searchIcon.svg'
import {Link} from 'react-router-dom';
import './Journal.css'
import I from '../../assets/I.svg';
import FontPicker from '../../components/FontPicker/FontPicker';
import arrow from '../../assets/arrow.svg';
import addImg from '../../assets/addImg.png';
import addVid from '../../assets/addVid.png';
import addLink from '../../assets/addLink.png';
import leftAlign from '../../assets/leftAlign.png';
import centerAlign from '../../assets/centerAlign.png';
import rightAlign from '../../assets/rightAlign.png';
import U from '../../assets/U.svg';
import indent from '../../assets/indent.svg';
import Bold from '../../assets/Bold.svg';
import previousbutton from '../../assets/previousbutton.svg';
import nextbutton from '../../assets/nextbutton.svg';
import {useState, useCallback, useEffect} from 'react';
import Navbar from '../../components/Navbar/Navbar';
import MobileNavbar from '../../components/MobileNavbar/MobileNavbar';
import Sidenav from '../../components/Sidenav/Sidenav';
import { SketchPicker } from 'react-color';
import Cookies from 'universal-cookie';
import {useNavigate} from 'react-router-dom';

const Journal = () => {
    //Navbar functions
    const [toggle, setToggle ] = useState(true);
    const [fontColor, setFontColor] = useState({
        "hsl": {
            "h": 257.6912870411322,
            "s": 1,
            "l": 0.0066500000000000005,
            "a": 1
        },
        "hex": "#010003",
        "rgb": {
            "r": 1,
            "g": 0,
            "b": 3,
            "a": 1
        },
        "hsv": {
            "h": 257.6912870411322,
            "s": 1,
            "v": 0.013300000000000001,
            "a": 1
        },
        "oldHue": 248.5714285714286,
        "source": "hsv"
    });
    const [bgColor, setBgColor] = useState({
        "hsl": {
            "h": 257.6912870411322,
            "s": 1,
            "l": 0.0066500000000000005,
            "a": 1
        },
        "hex": "#010003",
        "rgb": {
            "r": 1,
            "g": 0,
            "b": 3,
            "a": 1
        },
        "hsv": {
            "h": 257.6912870411322,
            "s": 1,
            "v": 0.013300000000000001,
            "a": 1
        },
        "oldHue": 248.5714285714286,
        "source": "hsv"
    });
    
    const [bold, setBold] = useState();
    const [underline, setUnderline] = useState();
    const [italic, setItalic] = useState();
    const [textSize, setTextSize] = useState(20);
    const handleChange = fontColor => setFontColor(fontColor);
    const handleBgChange = bgColor => setBgColor(bgColor);
    const [colorToggle, setColorToggle] = useState(false);
    const [bgColorToggle, setBgColorToggle] = useState(false);
    const [selectedFont, setSelectedFont] = useState('Comfortaa');
    
    
    const handleToggle = useCallback(() => setToggle(prevToggle => !prevToggle),[]);
    const cookies = new Cookies();
  const [videoFile, setVideoFile] = useState(null);
  const [uploadProgress, setUploadProgress] = useState(0);
  const [uploadStatus, setUploadStatus] = useState('idle');
  const hiddenFileInput = React.useRef(null);
const navigate = useNavigate();

  const handleUploadClick = event => {
    
    const accessToken = cookies.get('access_token');
    if (!accessToken) {
        alert('Please log in to upload videos.');
        navigate('../../login'); 
        return;
    }
    hiddenFileInput.current.click();
};

  const handleVideoSelect = event => {
    const file = event.target.files[0];
    if (file) {
        setVideoFile(file);
    }
  };

  const uploadVideo = async () => {
    if (!videoFile) {
        alert('Please select a video file to upload.');
        return;
    }

    const accessToken = cookies.get('access_token');
    if (!accessToken) {
        alert('You must be logged in to upload videos.');
        navigate('../../login')
        return;
    }

    const formData = new FormData();
    formData.append('video', videoFile);

    setUploadStatus('uploading');
    try {
        const response = await fetch('http://localhost:3000/api/videos', {
            method: 'POST',
            headers: {
                'Authorization': `Bearer ${accessToken}`,
            },
            body: formData,
        });

        if (!response.ok) {
            throw new Error('Network response was not ok');
        }

        const result = await response.json();
        alert('Video uploaded successfully!');
        console.log(result);
        setUploadStatus('success');
    } catch (error) {
        console.error('Upload failed:', error);
        alert('Upload failed: ' + error.message);
        setUploadStatus('error');
    }
};

    useEffect(() => {
        if (videoFile) {
            uploadVideo();
        }
    }, [videoFile])
  return (
    <>
    <Navbar onToggle={handleToggle} backgroundColorHover={true}/>
    <MobileNavbar toggle={toggle}/>
    <Sidenav />
    <section className="font-mindly-main flex flex-col">
        <h1 className="text-center text-font"> Your Journal</h1>
        <div class="container">
            <div class="first-column">
                <form style={{backgroundColor: bgColor.hex}} id="paper" method="get" action="">
                    <textarea style={{...{color:fontColor.hex},...{backgroundColor: bgColor.hex},...bold ? { fontWeight:'bold'} : {fontWeight : 'normal'},...italic ? { fontStyle:'italic'} : {fontStyle : 'normal'},...underline ? { textDecoration:'underline'} : {textDecoration : 'none'},...{fontSize: textSize+'px'},...{fontFamily: selectedFont.value}}} placeholder="Enter something funny." 
                        id="journal_area" 
                        name="text" 
                        rows="14"></textarea>  
                </form>
            </div>
            <div class="second-column">
                <div className='w-fit p-8 bg-mindly-green' class="customizeCenter"> 
                    <h2 className='m-4 mb-8 w-full  text-center text-4xl'>Customize</h2>
                    <div class="line">
                        <p class="options">Font</p>
                        
                        <FontPicker selectedFont={selectedFont} setSelectedFont={setSelectedFont}/>
                        
                    </div>
                    <div class="line">
                        <p class="options">Text Size</p>
                        <input 
                            className='w-8 ml-12 text-center'
                            type="text"
                            onChange={e => setTextSize(e.target.value)}
                            value={textSize}
                        />
                        <div className='flex items-center ml-8'>
                        <button><img class="icons" onClick={()=> setUnderline(!underline)}src={U}/></button>
                        <button><img class="icons" onClick={()=> setItalic(!italic)} src={I}/></button>
                        <button><img class="icons" onClick={()=> setBold(!bold)} src={Bold}/></button>
                        </div>
                        
                    </div>
                    <div class="line">
                        <p class="options">Text Color</p>
                        <div className='flex flex-col'>
                        <button onClick={()=>setColorToggle(!colorToggle)}><div style={{backgroundColor:fontColor.hex}} className='w-8 h-8 m-4'></div></button>
                        {colorToggle && <SketchPicker color={fontColor} onChangeComplete={handleChange} />}
                        </div>
                       
                        <p2>BG Color</p2>
                        <div className='flex flex-col'>
                        <button onClick={()=>setBgColorToggle(!bgColorToggle)}><div style={{backgroundColor:bgColor.hex}} className='w-8 h-8 m-4'></div></button>
                        {bgColorToggle && <SketchPicker color={bgColor} onChangeComplete={handleBgChange} />}
                        </div>
                    </div>
                    <div className='w-full flex justify-center' class="line buttons">
                       
                        
                    </div>

                    <div className='flex w-fit mx-auto'>
                        <img className='m-4' src={addVid} alt="Upload Video" onClick={handleUploadClick} />
                        <img className='m-4' src={addLink}/>
                        <img className='m-4' src={addImg}/>
                    </div>
                    <input
                        type="file"
                        ref={hiddenFileInput}
                        onChange={handleVideoSelect}
                        style={{display: 'none'}}
                        accept="video/*"
                    />
                </div>
                <div class="line buttons">
                    <a href="#"><img class="page" if="backpage"src={previousbutton}/></a>
                    <a href="#"><img class="page" id="nextpage" src={nextbutton}/></a>
                </div>
            </div>
            <input
                type="file"
                ref={hiddenFileInput}
                onChange={handleVideoSelect}
                style={{ display: 'none' }}
                accept="video/*"
            />
            {uploadStatus === 'uploading' && (
                <div>Uploading... {uploadProgress}%</div>
            )}
            {uploadStatus === 'success' && <div>Upload successful!</div>}
            {uploadStatus === 'error' && <div>Upload failed. Please try again.</div>}
        </div>


        
        
        
        
    </section>
   </> 
  )

}
export default Journal;