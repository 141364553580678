import React from 'react';

const UpdateUserInfo = async (data) => {

  /**
   * This function gets updates user info
   */

  const port = process.env.REACT_APP_BACKEND_PORT


  const response = await fetch(`http://localhost:${port}/api/users`, {
    method: 'PUT',
    mode: 'cors',
    credentials: 'include',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(data)
  })
};

export default UpdateUserInfo;