import React, {useEffect} from 'react'
import LandingPageTitle from './LandingPageTitle'
import MindlyPic from './MINDLY.png'
import LandingPageButtons from './LandingPageButtons'
import './LandingPage.css'
import {useNavigate} from "react-router-dom";
import Cookies from "universal-cookie";



const LandingPage = () => {

    const navigate = useNavigate()
    const cookies = new Cookies()

    const navigateRegister = () => {
        navigate('/register')
    }

    const navigateLogin = () => {
        navigate('/login')
    }

    const navigateHome = () => {
        navigate('/home')
    }

  return (
    <>
      <div className="landingPage">
          <LandingPageTitle/>
          <div className="landingPage_btn">
              <LandingPageButtons buttonTextContent="Create account" stylingClass="register" onClick={() => navigateRegister()}/>
              <LandingPageButtons buttonTextContent="I have an account" stylingClass="login" onClick={() => navigateLogin()}/>
          </div>

          <div className="logo">
              <img src={MindlyPic} />
          </div>
          <div className="landingPage_footer">
              <p>Made By Project:Empower</p>
          </div>
      </div>
    </>
  )
}

export default LandingPage