export const articlesAndBlogs = [
    {
        title: "Psychology Today",
        description: "A reputable source covering a wide range of mental health topics.",
    },
    {
        title: "Verywell mind",
        description: "Provides accessible and evidence-based articles on various mental health conditions and self-help strategies.",
    },
    {
        title: "NAMI (National Alliance on Mental Illness",
        description: "Offers educational articles, personal stories, and resources on mental health conditions and advocacy.",
    },
    {
        title: "The Mighty",
        description: "Features articles and personal narratives from individuals with lived experiences of mental health challenges.",
    },
]
export const tedtalks = [
    {
        title: "TED Talk: \"The Power of Vulnerability\" by Brené Brown",
        description: "Discusses the importance of embracing vulnerability and fostering connection.",
    },
    {
        title: "TED Talk: \"All it Takes is 10 Mindful Minutes\" by Andy Puddicombe",
        description: "Explores the benefits of mindfulness and provides a simple meditation exercise.",
    },
    {
        title: "YouTube: \"Self-Compassion Break\" by Kristin Neff",
        description: "Guides viewers through a self-compassion exercise to cultivate self-kindness and resilience.",
    },
    {
        title: "YouTube: \"Managing Stress and Anxiety During a Crisis\" by Kati Morton",
        description: "Offers practical tips for coping with stress and anxiety in challenging times.",
    },
]
export const podcasts = [
    {
        title: "\"The Anxiety Coaches Podcast\" with Gina Ryan",
        description: "Focuses on anxiety management techniques and provides support for those living with anxiety.",
    },
    {
        title: "\"The Mental Illness Happy Hour\" with Paul Gilmartin",
        description: "Explores mental health, interviews guests, and discusses personal experiences in a candid and compassionate manner.",
    },
    {
        title: "\"The Calm Collective\" with Cassandra Eldridge",
        description: "Explores topics related to mindfulness, self-care, and personal growth.",
    },
]
    export const books = [
        {
            title: "\"Lost Connections: Uncovering the Real Causes of Depression—and the Unexpected Solutions\" by Johann Hari."
        },
        {
            title: "\"The Body Keeps the Score: Brain, Mind, and Body in the Healing of Trauma\" by Bessel van der Kolk.",
        },
        {
            title: "\"The Anxiety and Phobia Workbook\" by Edmund J. Bourne.",
        },
        {
            title: "\"The Gifts of Imperfection\" by Brené Brown.",
        },
    ]